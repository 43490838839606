.admin-modal_manage-user {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 60%;
    height: 80%;
    padding: 0;
    min-width: 450px;
    min-height: 760px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.admin-modal-block {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
}
.admin-modal-block .multi-value-suggestbox__container {
    z-index: 25;
}

.admin-modal-main-block {
    flex-grow: 1;
    justify-content: flex-start;
}

.admin-modal-block-title {
    font-size: 12px;
    color: #828282;
}

.admin-modal-bottom-border {
    border-bottom: 1px solid #D7D7D7;
}

.admin-modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.admin-modal-roles {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    font-size: 14px;
    width: 90%;
}

.two-cols-view {
    width: calc(50% - 15px) !important;
}

.padding-top {
    padding-top: 10px;
}

.padding-bottom {
    padding-bottom: 15px;
}

.role-style {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.configuration-option {
    margin: 0;
    height: 35px !important;
}

.configuration-option:hover {
    background: none;
}

.margin-top {
    margin-top: 15px;
}

.c-role-modal__block__row {
    padding-top: 0;
}

.checkbox-margin {
    margin-top: 1px;
    padding: 0
}

.check-box-disabled {
    color: #E4E4E4;
}

.admin_notify_dropdown {
    z-index: 20;
}
.admin_finance_dropdown {
    z-index: 10;
}
.admin_access_dropdown {
    z-index: 15;
}
.admin-permissions_container {
    margin: 10px 0 0 0 !important;
    display: flex;
    flex-wrap: wrap;
}
.admin-permissions_container-block {
    width: 33%;
    min-height: 150px;
    margin-bottom: 10px;
}
.admin-permissions_line {
    color: rgba(0, 0, 0, 0.54);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
}
.admin_check {
    width: 100% !important;
    position: relative;
    display: flex;
    align-items: center;
    height: 30px !important;
    user-select: none;
    transition: 0.3s;
}
.item-append {
    cursor: default;
    padding-left: 1rem;
    border-left: 1px solid #D7D7D7;
    flex-grow: 0;
    margin-left: 1rem;
}