.filter-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 50px;
    margin: 20px;
    position: relative;
}
.filter-years {
    width: 350px;
}

.suggest-wrapper {
    width: 400px;
    position: relative;
    display: inline-block;
}
.suggest_wrapper-clients {
    z-index: 15;
}
.suggest-wrapper>div {
    width: 350px;
    height: 50px;
    margin: 20px;
}
.search-dropdown-filter-container {
    margin: 20px !important;
    min-height: 50px;
    justify-content: flex-end;
    z-index: 15;
}
.search-dropdown-filter {
    width: 350px;
    height: 35px;
    min-height: 35px;
    z-index: 10;
}

.search-dropdown-filter > div {
    border-radius: 0;
}

.filter-item__multi-box div div:nth-child(2) .multi-value-suggestbox__control {
    border: 1px solid red;
    margin: 1px;
}

.filter-item__multi-box div div:nth-child(2) .multi-value-suggestbox__control--is-focused{
    margin-top: 2px !important;
  }

.filter-item__multi-box div div:nth-child(2) .multi-value-suggestbox__control--is-open{
    margin-top: 2px !important;
}

.filter-container_multi-select {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 52px;
    width: 350px;
    margin: 20px;
}

.filter-container_multi-select div:nth-child(2) {
    height: 60px !important;
}

.filter-container_multi-select div:nth-child(2) svg {
    margin-top: 2px;
    margin-right: 5px;
}

.filter-container__data-picker {
    margin-top: 3px !important;
}

.filter-container__data-picker--class div{
    height: 38px !important;
}

.filter-container__data-picker--class label + div {
    margin-top: 14px !important;
}

.data-pisker__filter--margin {
    margin-left: 15px !important;
}
.data-pisker__filter--margin-sow {
    margin: 0 0 2px 20px !important;
}
.date_picker-toolbar {
    width: 400px !important;
    height: 55px !important;
}
.filter-container_years-select {
    height: 38px;
}

.filter-container_years-select div {
    height: 38px;
    line-height: 38px;
    margin: 0;
    padding: 0 20px;
    color: rgb(10, 10, 10);
}

.filter-years div label + div {
    margin-top: 14px;
}

.filter-years div label + div::after {
    transition: none;
    border-bottom: 0;
}

.filter-years div label + div::before {
    transition: none;
    border-bottom: 0;
}

.filter-container_years-select div div:focus {
    background-color: transparent !important;
}

.toolbar_textfield-filter {
    height: 60px;
    width: 350px !important;
    margin: 20px 30px 20px 20px !important;
}
.toolbar_textfield-filter_clear {
    position: absolute;
    top: 55%;
    right: 4%;
    transform: scale(0.6);
    cursor: pointer;
}

.z-index_50 {
    z-index: 50 !important;
}
.z-index_48 {
    z-index: 48 !important;
}
.z-index_46 {
    z-index: 46 !important;
}
.z-index_44 {
    z-index: 44 !important;
}
.z-index_42 {
    z-index: 42 !important;
}
.z-index_40 {
    z-index: 40 !important;
}
.z-index_38 {
    z-index: 38 !important;
}
.z-index_36 {
    z-index: 36 !important;
}
.z-index_34 {
    z-index: 34 !important;
}
.z-index_32 {
    z-index: 32 !important;
}
.z-index_30 {
    z-index: 30 !important;
}
.z-index_28 {
    z-index: 28 !important;
}
.z-index_26 {
    z-index: 26 !important;
}
.z-index_24 {
    z-index: 24 !important;
}
.z-index_22 {
    z-index: 22 !important;
}
.z-index_20 {
    z-index: 20 !important;
}
.z-index_18 {
    z-index: 18 !important;
}
.z-index_16 {
    z-index: 16 !important;
}
.z-index_14 {
    z-index: 14 !important;
}
.z-index_12 {
    z-index: 12 !important;
}
div.filter-container__date-picker {
    margin: 20px !important;
}
