.settings-page-toolbar {
    height: calc(100% - 50px);
}
.settings-page {
    width: 100%;
    min-height: 100px;
}
.settings-page span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}
.settings-notifications {
    width: 50%;
}
.settings-container {
    width: 50%;
    height: 100%;
    display: inline-block;
}
.settings-container ul {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
}
.settings-container ul li {
    margin-top: 20px;
}
.settings-container ul li input {
    width: 100px;
}
.settings-container ul li span {
    font-weight: 500;
    margin-left: 40px;
}
.settings-buttons {
    position: absolute;
    bottom: 40px;
    width: 95%;
}