@keyframes showContainer {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.notification-container {
    position: absolute;
    z-index: 20;
    width: 360px;
    height: 94vh;
    top: 6vh;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    animation: showContainer 0.4s linear;
}

.notification-header {
    position: relative;
    width: 100%;
    height: 75px;
}

.notification-header h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
    margin: 25px 0 0 25px;
    width: 110px;
}

.notification-header-cross {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    right: 25px;
    cursor: pointer;
}

.notification-header-clear {
    display: block;
    margin: 30px 0 0 25px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #F26939;
    cursor: pointer;
    width: 60px;
}

.notification-header-clear:hover {
    text-decoration: underline;
}

.notification-body {
    width: 320px;
    height: 80%;
    margin: 20px auto;
}

.notification-body-item {
    width: 310px;
    min-height: 70px;
    margin: 10px auto;
    background: #F8F8F8;
    border-radius: 3px;
}

.notification-body-item:hover {
    background: rgba(255, 133, 0, 0.1)
}

.notification-icon {
    margin: 10px 0 0 10px;
    width: 24px;
    position: absolute;
}

.notification-body-item p {
    position: relative;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    color: #4B4B4B;
    padding: 15px 0 0 40px;
    width: 250px;
}

.notification-body-item span {
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #4B4B4B;
    opacity: 0.5;
    display: block;
    margin: 0 0 0 40px;
}

.notification-cross {
    float: right;
    margin: 15px 10px 0 0;
    opacity: 0.5;
    cursor: pointer;
}

.notification-message {
    position: relative;
    width: 302px;
    min-height: 45px;
    background: #4B4B4B;
    border-radius: 3px;
    z-index: 20;
    display: flex;
}

.notification-message h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 5px 10px 5px 0;
}

.notification-message-icon {
    margin: auto 10px auto 10px;
}

.s-alert-wrapper {
    position: absolute;
    height: auto;
    right: 15px;
    top: 35px;
    display: flex;
}

.notification-message-cross {
    float: right;
    margin: 15px 10px 0 0;
    cursor: pointer;
}
