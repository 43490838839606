.history-item {
    font-family: Roboto;
    font-style: normal;
    color: #4B4B4B;
}
.history-item:nth-child(even) {
    background-color: #F8F8F8;
}
.history-changes {
    margin: 10px;
}
.history-changes > div {
    display: inline-block;
    padding: 5px;
}
.history-changes > div:nth-child(even) {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}
.history-changes > div:nth-child(odd) {
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
.history-changes_badge {
    margin-left: 10px;
}
.history-changes-date {
    margin-left: 95px;
}
.history-changes-date > div {
    display: inline-block;
    padding: 5px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
.history-loader {
    position: fixed;
    width: 83%;
    height: 100%;
    background-color:  #F8F8F8;
    opacity: 0.5;
}

.history-loader > div {
    margin: 120px auto;
    width: 40px;
    opacity: 1;
}

.history-changes-date svg {
    /* width: 30px; */
}

.emptyBlock {
    margin-bottom: 15px;
}

.history-changes-date-empty {
    min-height: 25px;
}