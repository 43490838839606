.redux-form__create-client--buttons-step2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.redux-form__create-client--buttons {
    display: flex;
    flex-direction: row;
    height: 50vh;
    min-height: 70px;
}
.redux-form__create-client--buttons-left {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    align-items: flex-end;
}
.redux-form__create-client--buttons-right {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: flex-end;
}
.create-client-photo {
    width: 110px;
}
.redux-form__row_client {
    margin-top: 15px;
    display: flex;
}
.client-margin_top {
    margin-top: 40px;
}
.button_width-150 {
    width: 150px;
}
.standart-container-create_client {
    height: 80px;
    margin: 10px;
    width: 382px;
}
.create-contacts_client {
    margin-bottom: 40px;
}