.show-alert-content{
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    color: #000000;
    white-space: pre-line;
}

.show-alert-content__margin{
    margin-top: 13px;
}

.alertdialog-button-size{
    margin-bottom: 10px !important;
    margin-right: 20px !important;
    width:100px !important;
}
