.c-tabs {
  display: flex;
}

.highlight-tab {
  padding-left: 5px;
}

.c-tabs__title {
  /* margin-right: 46px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 100%;
  /* padding: 13px 46px 9px; */
  border-bottom: 5px solid #fff;
}

.c-tabs__title:hover {
  cursor: pointer;
}

.c-tabs__title__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;

  color: #BDBDBD;
}

.c-tabs__title--selected {
  border-bottom: 5px solid #F26939;
}

.c-tabs__title--selected .c-tabs__title__text {
  font-weight: bold;
  line-height: 24px;
  color: #000000;
}

.c-tabs__hidden {
  display: none;
}
