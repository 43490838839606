.page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 50px);
    /* height: 100%; */
    padding: 25px;
  }

  .employee-info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
  }

  .employee-details {
    position: relative;
    z-index: 3;
  }

  .employee-info__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #D7D7D7;
  }

  .employee-info__header__part {
    display: flex;
    align-items: center;
  }

  .employee-info__header__part .employee-title{
    font-weight: bold;
    font-size: 16px;
    height: auto;
    text-transform: uppercase;
  }

  .employee-info__header__part .status{
    padding: 3px 10px;
    border-radius: 5px;
    background: #27AE60;
    color: white;
  }

  .employee-info__header__part>span {
    margin: 0 25px 0 0;
  }

  .employee-info__header__part svg {
    margin: 0 10px 0 0;
  }

  .employee-info__header__part__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    transition: 0.3s;
    border: 1px solid transparent;
    margin: 0 0 0 20px;
    padding: 0 15px;
    font-size: 16px;
    cursor: pointer;
  }

  .employee-info__header__part__button:hover {
    border-color: #f26939;
  }

  .employee-info__header__name {
    display: flex;
  }

  .employee-info__header__error {
    color: red;
  }

  .employee-info__main-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .employee-info__main-info__row {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    padding: 10px 0;
  }

  .employee-info__main-info__row__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15%;
    height: 50px;
    margin-bottom: 20px;
  }

  .employee-info__main-info__row__block__description{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 50px;
    margin-bottom: 20px;
  }

  .title {
    display: flex;
    align-items: flex-start;
    color: #828282;
    font-size: 12px;
  }

  .info {
    display: flex;
    align-items: center;
    cursor: text;
  }

  .info>span {
    pointer-events: none;
  }

  .employee-info__tabs {
    display: flex;
    flex-shrink: 0;
    height: 50px;
    border-top: 1px solid #D7D7D7;
    border-bottom: 1px solid #D7D7D7;
  }

  .item-details__tag {
    background-color: #F2F2F2;
    padding: 1% 3% 1% 3%;
    margin-right: 1%;
    border-radius: 6px;
    font-size: 12px;
    white-space: nowrap;
    display: inline-block;
    margin-top: 5px;
  }

  .employee-info__header__left {
    display: flex;
    width: 100%;
  }

  .employee-info__header__left__title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin-left: 20px;
  }

  .employee__select{
    margin-top: -31px !important;
    margin-right: 20px !important;
  }

  .change__clear-svg{
    position: absolute;
    top: 8px;
    right: 0;
    transform: scale(0.6);
    cursor: pointer;
  }

  .resume-input {
    width: 90%;
  }

  .resume-input .step__input {
    width: 100%;
  }

  .resume-input .step__input div{
    width: 90%;
  }

  .resume-member-inputs{
    min-height: 50px;
  }

  @media screen and (max-width: 1500px) {
    .manage-resume__input-discription{
      margin-left: 0px;
    }

    .manage-resume__input-link div{
      padding-right: 115px;
    }
  }

  .resume-buttom-cancel{
    float: right;
    height: 30px;
    margin-top: 20px;
    border: 1px solid rgba(242, 106, 57, 0.4);
  }

  .resume-buttom-apply{
    float: right;
    height: 30px;
    margin-top: 20px;
  }

  .big-row {
    width: 320px;
  }

  .employee_notes {
    width: 100%;
    height: auto;
  }
  .member_hover {
    width: 90%;
  }
  .member_hover:hover .edit_pencil {
    right: 0;
  }

  @media screen and (max-width: 1500px) {
    .resume-input .step__input>div{
      width: 200%;
    }

    .resume-input-discription .step__input>div{
      width: 120%;
    }
  }
  .employee-details__date-picker {
    margin-top: 5px !important;
  }
  .employee-details__date-picker input {
    padding: 5px;
  }

  .employees-resume-block-height{
    height: 100%;
    align-items: baseline;
  }

  .employees-resume-block-height div{
    height: auto;
  }

  .clear-datapicker-svg-members div .clear-svg-datapicker{
    right: 15px;
    top: 12px;
  }

  .question-mark {
    display: inline-block;
    height: 15px;
    vertical-align: bottom;
    cursor: pointer;
  }
.popover-cell {
    width: 200px;
    height: 50px;
    display: absolute;
    margin: -60px 0 0 -105px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.14);
    background: #FFEEDF;
    border: 1px solid #A5A5A5;
    z-index: 100;
    cursor: pointer;
}
.popover-cell p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    color: #727272;
    margin: 10px 0 0 16px;
}

.action-lable-document-margin{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  text-align: right;
}

.document-list__no-item{
  min-height:50px;
  padding: 17px;
  font-size: 13px;
  color: #4B4B4B;
  border-bottom: 1px solid #D7D7D7;
}
.highlight_skype {
  color: red;
}
.title_max-width {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title_max-width_email > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title_max-width_email:hover > p {
  white-space: unset;
  overflow: unset;
  width: inherit;
  background: #FFFFFF;
  z-index: 1;
}
.change-name__input-employee input::-ms-clear {
  display: none;
}
.employee__searchdropdown {
  min-width: 230px !important;
}
.employee__searchdropdown .search-dropdown-value-container > div {
  width: 60%;
}

.certification__dialog-width div div{
  overflow-y: visible;
  max-width: 700px;
}

.certificate-modal-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  align-items: baseline;
}

.certification-step__select>div {
  width: 230px;
  height: 35px;
  min-height: 35px;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.certification-step__select label span {
	color: orangered;
}

.certification-step__select div div {
  margin-left: 8px;
}

.certification-step__select-padding>div {
  display: flex;
  align-items: center;
  width: 218px !important;
  max-width: 218px;
  padding: 0 0 0 10px !important;
  font-size: 14px;
}
.certification-step__select-padding>svg {
  background: #F2F2F2;
  border-right: 1px solid #DFDFDF;
}

.certificate-modal-row .step__form .placeholder--is-active {
  color: grey;
}

.certification-step__text>div {
  height: 37px !important;
}

.background-check__dialog-width div div {
  overflow-y: visible;
  max-width: 700px;
}

.background-check-modal-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  align-items: baseline;
}

.background-check-step__select > div {
  width: 230px;
  height: 35px;
  min-height: 35px;
}

.background-check-step__select label span {
  color: orangered;
}

.background-check-step__select div div {
  margin-left: 8px;
}

.background-check-modal-row .step__form .placeholder--is-active {
  color: grey;
}

.background-check-step__text > div {
  height: 37px !important;
}

.background-check-step__checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
  height: 35px;
  min-height: 35px;
}

.employee-training__dialog-width div div {
  overflow-y: visible;
  max-width: 1500px;
}

.employee-training-modal-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.no-wrap {
  flex-wrap: nowrap !important;
  align-items: end;
}

.employee-training-step__select > div {
  width: 430px;
  height: 35px;
  min-height: 35px;
}

.employee-training-step__select label span {
  color: orangered;
}

.employee-training-step__select div div {
  margin-left: 8px;
}

.employee-training-modal-row .step__form .placeholder--is-active {
  color: grey;
}

.employee-training-step__text > div {
  height: 37px !important;
}

.employee-training-step__date > div {
  width: 230px;
  height: 35px;
  min-height: 35px;
}

.employee-training-step__checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  height: 37px;
  min-height: 37px;
}

.training-button-apply {
  float: right;
  height: 30px;
}

.training-button-cancel {
  float: right;
  height: 30px;
  border: 1px solid rgba(242, 106, 57, 0.4);
}

.training-confirmation-buttons {
  min-height: 50px;
}

.training-inputs {
  min-height: 50px;
  margin-bottom: 10px;
}

.training-edit-shift {
  margin-left: 190px;
}

.training-edit-order input {
  padding: 0 20px 0 10px !important;
}
