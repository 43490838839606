.c-list__item-milestone {
    display: flex;
    justify-content: stretch;
    align-items: center;
    flex-shrink: 0;
    font-size: 14px;
    color: #4B4B4B;
    height: 50px;
    transition: 0.3s;
}

.c-list__item-milestone .MuiInputBase-root {
    font-size: inherit;
}

.milestone-item__container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
}

.step__form--milestone-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    flex-basis: 150px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: visible;
    height: 100%;
}

.step__form--milestone-block--action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 140px;
    padding: 0 10px;
    white-space: nowrap;
    overflow: visible;
    height: 100%;
}

.step__form--milestone-block--text-field {
    height: 50px;
    justify-content: center;
    cursor: pointer;
}

.step__form--milestone-block--date-picker {
    height: 50px;
    justify-content: center;
}

.milestone-item__button-disabled {
    cursor: default !important;
    pointer-events: none !important;
    color: #8f8f8f !important;
}

.milestone-item__button-disabled path {
    fill: #8f8f8f !important;
}

.milestone-item__action-disabled {
    cursor: default !important;
    pointer-events: none !important;
    color: #A5A5A5 !important;
}

.step__form--milestone-block--select {
    justify-content: center;
    width: 100%;
    margin: 5px 10px;
    max-width: 160px;
}

.step__form--milestone-block--action--item {
    justify-content: center;
    margin: 0 5px !important;
    cursor: pointer;
    color: black;
}

.step__form--milestone-block--select div div div{
    padding: 7px 0 7px 10px;
}

.step__form--milestone-block--override {
    margin-left: 5px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.step__form--milestone-block p.Mui-error {
    position: absolute;
    bottom: -18px;
}

.block__button-presets {
    display: flex;
    color: blue;
}

.span__button-presets {
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
}

.span__button-presets__disabled {
    color: #E4E4E4!important;
    pointer-events: none !important;
    cursor: default !important;
}

.milestones-action-label {
    flex-basis: 140px;
}