.standart-container-offices {
    height: 80px;
    width: 280px;
    margin: 10px;
}
.redux-form-offices__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
