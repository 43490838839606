.admin-modal_merge-container {
    position: absolute;
    width: 55%;
    height: 20%;
    min-width: 800px;
    min-height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0 20px 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.admin_merge-checkbox {
    opacity: 0;
}
.admin_merge-checkbox:hover {
    opacity: 1;
}
.checked_item-merge{
    opacity: 1;
}
.admin-modal-block_merge {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 20px;
}

.manage-users__main-block--buttom-size {
    margin-bottom: 25px;
}

.add-locations__cancel-button {
    margin-right: 5px;
}