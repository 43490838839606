.white-space-for-projects {
    white-space: nowrap;
}

.office-container {
    width: 100%;
    height: calc(100vh - 50px);
    padding: 25px;
}

.office-info {
    width: auto;
    min-width: 250px;
}

.office-info:hover {
    width: auto;
    min-width: 250px;
    padding-right: 20px;
}

.office-name {
    width: auto;
    min-width: 100px;
}

.office-name:hover {
    width: auto;
    min-width: 100px;
    padding-right: 20px;
}

.office-name:hover > svg {
    margin: 0;
}

.office-white-space {
    white-space: nowrap;
}

.biggest-row {
    width: 500px;
}
.calendar_holidays-container {
    padding: 10px;
    margin-top: 10px;
}
.calendar_holidays-header {
    display: flex;
    flex-shrink: 0;
    height: 40px;
    font-style: italic;
    font-size: 13px;
    color: #4B4B4B;
    margin-top: 20px;
    padding: 0 10px;
}

.cell-container_datepicker>div {
    width: 200px;
    height: 35px;
    min-height: 35px;
}

.cell-container_datepicker--auto-width div {
    width: auto;
}

.toolbar-button_office-details {
    right: 0;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    user-select: none;
    padding: 0 10px;
    border: 1px solid transparent;
    margin-bottom: 10px;
    width: 150px;
    max-height: 30px;
}

.toolbar-button_office-details svg {
    margin: 0 10px 0 0;
    transition: 0.3s;
}

.toolbar-button_office-details:hover {
    border-color: #f26939;
}

.add-calendar--block {
    border-top: 1px solid #D7D7D7;
    border-bottom: 1px solid #D7D7D7;
}

.add-calendar--text-field-block {
    max-width: 650px;
}

.add-calendar--text-field {
    margin-top: 6px !important;
}

.add-calendar--select div{
    margin-top: 2px !important;
    font-size: 14px !important;
}

.add-calendar--datepicker div {
    font-size: 14px !important;
}

.add-calendar--button-block {
    margin: 0 0 20px auto;
}

.add-calendar--selectors-block {
    display: flex;
    justify-content: space-between;
}

.add-calendar--selectors-block div:not(:first-child) {
    margin-left: 15px !important;
}

.calendar-office-select {
    margin-bottom: 10px !important;
}

#menu-yearForTheHolidays div:nth-child(2) {
    max-height: 300px;
}

.calendar-title__container {
    display: flex;
}

.tolbar-button_ofice-calendar{
    margin: 5px 0 0 15px;
}

.office_calendar-info{
    display: flex;
    align-self: center;
    height: 25px;
    width: 25px;
}
