.sidebar-cont {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    background: #4B4B4B;
    transition: 0.2s;
    -o-transition: 0.2s;
    animation-duration: 0.2s;
    overflow: hidden;
}

.sidebar-container a {
    text-decoration: none;
}

.sidebar-container a:active {
    outline: none;
}

.sidebar-container a:focus  {
    outline: none;
}

.expanded {
    width: 225px;
}

.collapsed {
    width: 65px;
}

.selected-tab {
    background: black;
    border: hidden;
    outline: hidden;
}
.sidebar-row:target {
    border: hidden;
    outline: hidden;
}
.sidebar-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    transition: 0.2s;
	-o-transition: 0.2s;
    cursor: default;
    border: hidden;
    outline: hidden;
}

.sidebar-row-dropdown {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.2s;
	-o-transition: 0.2s;
    cursor: pointer;
    padding: 10px 15px 10px 78px;
    color: white;
    user-select: none;
    font-size: 14px;
}

.sidebar-row-dropdown:hover {
    background-color: #222222;
}

.sidebar-option:hover {
    background: black;
    cursor: pointer;
}

.logo {
    display: flex;
    align-items: center;
    margin: 0 0 0 35px;
    cursor: pointer;
}

.sidebar-row-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    cursor: pointer;
}

.sidebar-row-text {
    color: white;
    user-select: none;
    margin: 0 0 0 35px;
}

.text-expanded {
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
	-o-transition: 0.5s;
}

.text-collapsed {
    opacity: 0;
    visibility: hidden;
}

.split-dashboard {
    margin: 5px 0;
}

.admin-tools {
    padding-left: 9.33%;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;

    color: rgba(255, 255, 255, 0.54);
}

.sidebar-container{
    flex: 1 0 auto;
}

.sidebar-footer{
    min-height: 40px;
    width: 225px;
    text-align: center;
    flex: 0 0 auto;
}

.version-tools{
    user-select: none;
    color: white;
}

.links__text-decoration--none {
    width: max-content;
    text-decoration: none;
    color: black;
}
