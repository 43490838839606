
.edit-assignment__item-form {
    height: 35px;
    flex-grow: 0;
}

div.edit-assignment__text-field, div.edit-SOW__select {
    background: #F2F2F2;
    border: 1px solid #DFDFDF;
    height: 100%;
}
  
.edit-assignment__text-field input{
    padding: 0 10px;
    font-size: 14px;
}
  

.edit-assignment__text-field textarea {
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
}
  
.edit-SOW__select>div, .edit-SOW__select>div>div, .edit-assignment__text-field>div, .edit-assignment__text-field>div>div {
    height: 100%;
}
  
.edit-assignment__text-field>div {
    padding: 0 0 0 10px;
}
  
.edit-assignment__text-field>div>div {
    padding: 0 0 0 10px;
    margin: 0;
    overflow-x: hidden;
}
  
.edit-SOW__select>div>div {
    display: flex;
    align-items: center;
}
  
.edit-assignment__text-field>div::after, .edit-assignment__text-field>div::before, .edit-SOW__select::after, .edit-SOW__select::before {
    content: none !important;
}
  
.edit-SOW__select>div>div {
    padding: 0 20px;
    font-size: 14px;
}

.margin_container {
    margin: 0 30px 0 10px;
    min-width: 350px;
}
.assignment_picker-form {
    width: 165px;
}
.standart-container-assignment {
    height: 55px;
    margin: 10px;
}
.redux-form-assignment__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.assignment_text-form {
    width: 340px;
}
.assignment_text-form_backup {
    width: 350px;
}
.picker_container-form {
    margin: 0 30px 0 30px;
}
.assignment_select_container-form {
    margin-left: 30px;
}
.redux-form__assignment--buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 30vh;
    min-height: 70px;
}
.standart-container-assignment .field__error-container {
    width: 165px;
}
