.employee-skills-modal__container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px;
    position: absolute;
    width: 100%;
    max-width: 60%;
    height: 100%;
    max-height: 40%;
    margin: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 4;
}

.employee-skills-modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.employee-skills-modal__title {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}
.employee-skils-modal__fields {
    display: flex;
    justify-content: space-between;
}

.employee-skills-modal__buttons {
    display: flex;
    justify-content: flex-end;
}

.step__select .MuiSelect-root-23{
    padding-top: 2px;
}

.employee-skills-modal__container .multi-value-suggestbox__label{
    line-height: 0.9;
}

.employee-skils-modal__fields .clear-svg {
    top: 32px;
    right: 21%;
}
.employee-skils-modal__fields label{
    top: 9px;
    right: 20%;
}
.multi-value-suggestbox__multi-value {
    background-color: #bdbdbd !important; 
}

.add-skills-MultiSuggestBox-position{
    padding-top: 10px;
}
.skill_error-container {
    color: red;
    font-size: 14px;
    height: 20px;
}

.employee-skills-modal__icon-cross {
    cursor: pointer;
}