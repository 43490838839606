.sort-item__ul{
    margin: 0;
    padding-left: 2px;
}

.sort-item__li{
    list-style-type: none;
    background: #F8F8F8;
}

.sort-item__span{
    padding:16px;
    float: left;
}

.sort-item__span svg{
    cursor: row-resize;
}

.resume-input-link{
    min-height: 50px;
    margin-bottom: 15px;
}

.resume-input-discription{
    min-height: 50px;
    margin-left: -75px;
    margin-bottom: 15px;
}

.resume-input-date{
    min-height: 50px;
    margin-left: -75px;
    margin-bottom: 15px;
    flex-grow: 1;
}

.document-item__container {
    width: 100%;
    height: 50px;
}

.step__form--document-block {
    width: 26%;
}

.step__form--document-block > div {
    margin-bottom: 7px;
    padding-right: 10px;
}
.step__form--document-block input {
    height: 33px;
}

.document-item__buttom-cancel{
    float: right;
    height: 30px;
    margin-top: 10px;
    border: 1px solid rgba(242, 106, 57, 0.4); 
}

.document-item__buttom-apply{
    float: right;
    height: 30px;
    margin-top: 10px;
}

.step__form--document-block--select {
    justify-content: center;
    width: 25%;
}

.step__form--document-block--select div div div{
    padding: 7px 0 7px 10px;
}

.step__form--document-block-no-select {
    width: 40%;
}

.c-list-item__options-column-container--no-option {
   width: 90px;
}

.c-list-item_push-options-up_low {
  top: -30px !important;
}

.employees-resume-block-height div .c-editable-list-item-delete-block__option{
    height: 35px;
}

.c-editable-list-item-delete-block__option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 35px;
    background: white;
    transition: 0.3s;
}

.c-editable-list-item-delete-block__option:hover {
    background: #DFDFDF;
}
