.form-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.margin-right-50 {
  margin-right: 50px !important;
}

.c-SOW-edit__title {
  font-weight: bold;
}

.c-SOW-edit__checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  margin: 10px 50px 10px 0;
}

.c-SOW-edit__checkbox-container label {
  cursor: pointer;
}

.c-SOW-edit__checkbox-title {
  font-size: 12px;
  font-weight: normal;
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  /* line-height: 12px; */
}

.c-SOW-edit__form {
  width: 350px !important;
  flex-grow: 0;
}

.c-SOW-edit__form input {
  padding: 0 40px 0 10px;
}

.c-SOW-edit__form>.c-SOW-edit__input input#rate {
  padding: 0 10px;
}

.c-SOW-edit__form p {
  margin: 0 -10px 0 20px;
}

.c-SOW-edit__form .clear-svg {
  right: 7%;
}

.c-SOW-edit__date-picker .material-icons {
  transform: scale(0.9);
}

.c-SOW-edit__date-picker button {
  padding: 5px;
}

.c-SOW-edit__select>div>div {
  padding: 8px 20px;
}

.c-SOW-edit__input>div:after, .c-SOW-edit__select:after, .c-SOW-edit__select:before, .c-SOW-edit__date-picker>div:after, .c-SOW-edit__input>div:after, .c-SOW-edit__input>div:before {
  content: none !important;
}

.c-SOW-edit__date-picker>div::before {
  border-color: transparent !important;
}

.c-SOW-edit__form>div>div {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  font-size: 14px;
}

.c-SOW-edit__input>div, .c-SOW-edit__select>div, .c-SOW-edit__date-picker>div {
  width: 100%;
}

.c-SOW-edit__description>div {
  display: flex;
  align-items: flex-start;
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  height: 110px;
  padding: 10px 0 10px 20px;
  box-sizing: border-box;
}

.c-SOW-edit__description>div>div {
  height: 100%;
}

.c-SOW-edit__description textarea {
  height: 100% !important;
  box-sizing: border-box;
  overflow: auto;
  font-size: 14px;
}

.c-SOW-edit__list {
  padding: 15px 0 0 0;
}

.c-SOW-edit__add-new-container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 19px;
}

.c-SOW-edit__add-new {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 0 0 20px; */
  height: 35px;
  cursor: pointer;
  user-select: none;
  padding: 0 10px;
  border: 1px solid transparent;
  transition: 0.3s;
}

.c-SOW-edit__add-new span {
  line-height: 24px;
}

.c-SOW-edit__add-new svg {
  margin: 0 10px 0 0;
  transition: 0.3s;
}

.c-SOW-edit__add-new:hover {
  border-color: #f26939;
}

.c-edit .buttons {
  justify-content: flex-end;
  margin: auto 0 0 0;
}
.c-SOW-edit__input > label > span  {
  color: orangered !important;
}
.c-SOW-edit__form > label > span  {
  color: orangered !important;
}
.c-SOW-edit__date-picker > label > span  {
  color: orangered !important;
}