.team-page {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #fff;   
}

.team-page__header{
  padding: 0 0 10px 5px;
}

.team-page__container {
  height: 100%;
}

.team-page__content {
  padding: 10px 5px;
  height: auto;
}

.team-page__input {
  display: flex;
  margin-bottom: 30px;
}

.team-page__input-with-error {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.team-page__error {
  color: red;
  height: 10px;
  margin-top: 5px;
}

.team-page__members-input {
  width: 100%;
  max-width: 753px;
  z-index: 30;
  height: auto;
}
