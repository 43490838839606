.header-cont {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 50px;
    border: 1px solid #e8e8e8;
}

.main-part {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    padding-left: 25px;
}

.main-part-item {
    margin: 0 25px 0 0;
}

.add-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 0;
    opacity: 0;
    top: 40px;
    color: black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: 0.2s;
    visibility: hidden;
    z-index: 999;
}

.add-menu::before {
    content: '';
    position: absolute;
    top: -10px;
    width: 100%;
    height: 10px;
}

.add-menu::after {
    content: '';
    position: absolute;
    top: -20px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 10px solid #F8F8F8;
    ;
    box-sizing: border-box;
}

.add-menu-option {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 0 0 20px;
    transition: 0.3s;
    cursor: pointer;
    background: #F8F8F8;
    ;
    z-index: 1;
}

.add-menu-option:hover {
    background: #e8e8e8;
}

.expanded-add-menu {
    opacity: 1;
    height: 120px;
    visibility: visible;
}

.new-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 30px;
    background: #F26939;
    color: white;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;
}

.new-button:hover {
    background: #D95E33;
}

.notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.user-menu-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    height: 100%;
    min-width: 200px;
    box-sizing: border-box;
    border-left: 1px solid #e8e8e8;
    cursor: pointer;
}

.user-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100;
    opacity: 0;
    top: 48px;
    right: 1px;
    border: 1px solid #e8e8e8;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
    transition: 0.2s;
    visibility: hidden;
    z-index: 999;
}

.user-menu-option {
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 200px;
    padding: 5px 0 5px 20px;
    transition: 0.3s;
    cursor: pointer;
    background: white;
}

.user-menu-option:hover {
    background: #e8e8e8;
}

.expanded-user-menu {
    opacity: 1;
    min-height: 40px;
    /* height: 120px; // TODO-TDLT-482: uncomment me when design of My Account and Settings page will be done */
    visibility: visible;
}

.user-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 10px;
    user-select: none;
}

.user-menu-expander {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    height: 50%;
    width: 20px;
    transition: 0.3s;
    transform: rotate(0);
}

.expanded__user-menu__expander {
    transform: rotate(180deg);
}

.user-menu-option .checkbox__container {
    margin: 0 10px 0 0 !important;
}
