.employee-sync-modal {
    padding: 0 20px 20px;
    position: absolute;
    width: 400px;
    max-width: 60%;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 999;
}

.employee-sync-modal .buttons {
    justify-content: flex-end;
    margin: auto 0 0 0;
    border-top: 1px solid #D7D7D7;
}

.employee-sync-modal__title--margin {
   margin: 10px 0;
}

.sync-modal {
    display: flex;
    align-items: center;
}

.sync-modal > span {
    margin-left: 10px;
}
.sync-modal-container {
    padding: 20px !important;
}

.c-role-modal__icon-cross {
    cursor: pointer;
}
