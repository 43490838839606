.audit-list__content .c-list__item-cont {
    height: unset;
    min-height: 50px;
}

.audit-list__content .c-list__item-column {
    padding: 10px;
    height: unset;
    word-break: break-all;
}
