.search-dropdown-root {
    display: flex;
    width: 400px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.search-dropdown-container {
    height: 50px;
    width: 350px;
}

.search-dropdown-container>div {
    height: 35px;
    width: 350px;
}

.step__search-dropdown>div {
    border: 1px solid #dfdfdf !important;
    box-shadow: none;
    cursor: pointer;
    border-radius: 0;
    z-index: 29;
}

.search-dropdown-value-container>div>div>input {
    flex-grow: 1;
    overflow: hidden;
    width: 100% !important;
}

.search-dropdown-value-container>div>div>div {
    height: 100% !important;
}

.search-dropdown-value-container>div>div:first-child {
    height: 100%;
    width: 100%;
}

.search-dropdown-label {
    font-size: 12px;
    color: #828282;
}

.search-dropdown-no-value {
    padding: 0 0 0 20px;
}

.search-dropdown-value-container {
    height: 100%;
    flex-grow: 1;
    padding: 0 0 0 20px;
}

.search-dropdown-value-container>div {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.search-dropdown-value-container input {
    color: black !important;
}

.no-options-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    padding: 11px 16px;
}

.add-new-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.add-new-item>i>svg {
    margin: 0 10px 0 0;
}


.search-dropdown-clearable>div:first-child {
    max-width: calc(100% - 85px);
}

.search-dropdown-not-clearable>div:first-child {
    max-width: calc(100% - 60px);
}

.step__search-dropdown.step__search-dropdown-row-1>div {
    z-index: 28;
}

.step__search-dropdown.step__search-dropdown-row-2>div {
    z-index: 27;
}

.step__search-dropdown.step__search-dropdown-row-3>div {
    z-index: 26;
}
