.multi-value-suggestbox__control {
    border-radius: 0px!important;
    border: 1px solid #DFDFDF!important;
    box-sizing: border-box;
    /* max-height: 35px !important; */
    height: auto;
    background: #F2F2F2 !important;
    /* min-height: 35px !important; */
}

.multi-value-suggestbox__control--is-focused {
    border-radius: 0px!important;
    border: 1px solid #DFDFDF!important;
    box-sizing: border-box;
    /* height: auto !important; */
}

.multi-value-suggestbox__container--menu-is-open {
    border: 1px solid #DFDFDF!important;
}

.multi-value-suggestbox__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
    height: 50px;
}

.multi-value-suggestbox__container :last-child() {
    height: 35px;
}

.multi-value-suggestbox__label {
    display: flex;
    flex-direction: row;
    height: 12px;
}

.multi-value-suggestbox__label>span {
    width: fit-content;
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 12px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
}

.multi-value-suggestbox__placeholder {
    margin-left: 10px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    color: #999 !important;
    opacity: 1;
}

.multi-value-suggestbox__multi-value {
    background-color: #a7a7a7 !important;
    margin-bottom: 4px !important;
}

/* .multi-value-suggestbox__control--is-focused { 
    border: 1px solid orangered!important;
}

.multi-value-suggestbox__control--menu-is-open { 
    border: 1px solid orangered!important;
} */
