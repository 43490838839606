.c-list__item-renewal-status {
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  color: #4B4B4B;
  height: 43px;
  transition: 0.3s;
}

.c-list__item-renewal-status .MuiInputBase-root {
  font-size: inherit;
}

.renewal-status-item__container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.step__form--renewal-status-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-basis: 150px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: visible;
  height: 100%;
}

.step__form--renewal-status-block--action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 140px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: visible;
  height: 100%;
}

.step__form--renewal-status-block--field {
  height: 35px;
  cursor: default;
  padding: 0 10px 0 0;
  flex-grow: 2.2;
}

.step__form--renewal-status-block-text-field {
  height: 50px;
  justify-content: center;
}

.step__form--renewal-status-block--date-picker {
  height: 50px;
  justify-content: center;
}

.renewal-status-item__button-disabled {
  cursor: default !important;
  pointer-events: none !important;
  color: #8f8f8f !important;
}

.renewal-status-item__button-disabled path {
  fill: #8f8f8f !important;
}

.renewal-status-item__action-disabled {
  cursor: default !important;
  pointer-events: none !important;
  color: #A5A5A5 !important;
}

.step__form--renewal-status-block--select {
  justify-content: center;
  width: 100%;
  margin: 5px 10px;
  max-width: 160px;
}

.step__form--renewal-status-block--action--item {
  justify-content: center;
  margin: 0 5px !important;
  cursor: pointer;
  color: black;
}

.step__form--renewal-status-block--select div div div{
  padding: 7px 0 7px 10px;
}

.step__form--renewal-status-block--override {
  margin-left: 5px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.step__form--renewal-status-block p.Mui-error {
  position: absolute;
  bottom: -18px;
}

.block__button-presets {
  display: flex;
  color: blue;
}

.span__button-presets {
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.span__button-presets__disabled {
  color: #E4E4E4!important;
  pointer-events: none !important;
  cursor: default !important;
}

.milestones-action-label {
  flex-basis: 140px;
}

.add-renewal-status__button--margin {
  display: flex;
  align-items: center;
  height: 35px;
  margin-top: 10px;
  padding-left: 3px;
  pointer-events: none !important;
  cursor: default !important;
}

.div__total-field {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  height: 25px;
  font-size: 13px;
}

.equals {
  color: greed;
  height: 25px;
}

.not-equals {
  color: red;
}

.popover-renewal-status {
  width: 300px;
  height: 35px;
  display: absolute;
  margin: 5px 3px 0 0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.14);
  background: #FFEEDF;
  border: 1px solid #A5A5A5;
  z-index: 2;
  cursor: pointer;
}
.popover-renewal-status p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: normal;
  color: #727272;
  margin: 2px 13px;
}

.container__renewal-status__fixed-width {
  height: 280px;
}

.icon-plus {
  margin-right: 10px;
}

.renewal-status-item__checkbox {
  max-width: 35px;
  padding: 0 0 0 10px;
}

.col__renewal-status-type {
  flex-grow: 2.2;
  padding-left: 10px;
}

.col__renewal-value {
  padding-left: 15px;
  flex-grow: 2.5;
}

.step__form--renewal-status-block--text-field {
  flex-grow: 2.5;
  padding-left: 15px;
}

.control--renewal-status-value {
  width: 75px;
}