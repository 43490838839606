* {
  box-sizing: border-box;
}

.app {
  display: flex;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  font-size: 14px;
}

.page {
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 1280px;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100vw - 225px);
  height: 100vh;
}

.scrollbars>div {
  overflow: auto !important;
}

.list-with-horizontal-track {
  padding-bottom: 4px;
}

.track-horizontal {
  position: absolute;
  height: 6px;
  right: 2px;
  bottom: 0px;
  left: 2px;
  border-radius: 3px;
}

.track-horizontal div {
  background-color: #A5A5A5 !important;
}

.checkbox-cont-list-header {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 0 56px 0 0;
}

.checkbox-cont-list-item {
  position: relative;
  width: 100%;
  height: 16px;
  margin: 0 15px 0 0;
}

.checkbox-cont-list-header input, .checkbox-cont-list-item input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid #BDBDBD;
  cursor: pointer;
  transition: 0.3s;
}

.checkbox-cont-list-item input:checked~.checkbox,
.checkbox-cont-list-item input:disabled:checked~.checkbox,
.checkbox-cont-list-item input:checked~.configuration-option__checkbox,
.checkbox-cont-list-item input:disabled:checked~.configuration-option__checkbox {
  background-color: #F26939;
  border: 1px solid #F26939;
}

.checkbox:after, .configuration-option__checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-cont-list-item input:checked~.checkbox:after,
.checkbox-cont-list-item input:checked~.configuration-option__checkbox:after {
  display: block;
}

.checkbox-cont-list-item .checkbox:after, .checkbox-cont-list-item .checkbox:after, .checkbox-cont-list-item .configuration-option__checkbox:after {
  left: 30%;
  top: 10%;
  width: 3px;
  height: 6px;
  border: 1px solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.helper-text {
  position: absolute;
  top: 55px;
  margin: 0 !important;
  color: orangered !important;
  cursor: default;
}

.clear-svg {
  position: absolute;
  top: 50%;
  right: 18%;
  transform: scale(0.6);
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.u-flex-row {
  display: flex;
  flex-direction: row;
}

.u-flex-column {
  display: flex;
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align-items-center {
  display: flex;
  align-items: center;
}

.u-justify-center {
  justify-content: center;
}

.u-space-between {
  display: flex;
  justify-content: space-between;
}

.u-flex-1 {
  flex: 1;
}

.u-flex-2 {
  flex: 2;
}

.u-flex-3 {
  flex: 3;
}

.u-flex-4 {
  flex: 4;
}

.u-width-100pr {
  width: 100%;
}

.u-text-center {
  text-align: center;
}

.u-text-end {
  text-align: end;
}