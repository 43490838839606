.page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 50px);
  /* height: 100%; */
  padding: 25px;
}

.project-info {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.project-info__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 25px 0;
  height: 50px;
}

.project-info__header__part {
  display: flex;
  align-items: center;
}

.project-info__header__part .project-title{
  font-weight: bold;
  font-size: 16px;
  height: auto;
  text-transform: uppercase;
}

.project-info__header__part .status{
  padding: 3px 10px;
  border-radius: 5px;
  background: #27AE60;
  color: white;
  margin: 0 10px 0 10px;
}

.project-info__header__part>span {
  margin: 0 25px 0 0;
}

.project-info__header__part svg {
  margin: 0 10px 0 0;
}

.project-info__header__part__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  transition: 0.3s;
  border: 1px solid transparent;
  margin: 0 0 0 20px;
  padding: 0 15px;
  font-size: 16px;
  cursor: pointer;
}

.project-info__header__part__button:hover {
  border-color: #f26939;
}

.project-info__main-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.project-info__main-info__row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.project-info__main-info__row__block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20%;
  height: 50px;
  margin-bottom: 20px;
}

.project-info__main-info__row__block__description{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: 50px;
  margin-bottom: 20px;
}

.title {
  display: flex;
  align-items: flex-start;
  color: #828282;
  font-size: 12px;
  flex-shrink: 0;
  height: 30px;
}

.info {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.project-info__tabs {
  display: flex;
  flex-shrink: 0;
  height: 50px;
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
  /* z-index: 2; */
}


.description-info {
  overflow: hidden;
  height: auto;
  width: 100%;
}

.project__select{
  margin-top: -5px !important;
  margin-right: 20px !important;
  z-index: 3;
}
.billability-project-select {
  width: 250px;
}

.change__clear-svg-project {
  position: absolute;
  top: 0px !important;
  right: 10px !important;
  transform: scale(0.6);
  cursor: pointer;
}

.change__clear-svg-project-project_code {
  position: absolute;
  top: 35%;
  right: 20px !important;
  transform: scale(0.6);
  cursor: pointer;
}

.change__clear-svg-project-business_trip {
  position: absolute;
  top: 25% !important;
  right: 0;
  transform: scale(0.6);
  cursor: pointer;
}

.project-detail__date-picker {
  margin-top: 0px !important;
}
.hightlight {
  color: red;
}

.project_hover {
  width: 90%;
}
.project_hover_effect {
  position: relative;
  border: 1px solid transparent;
  padding: 2px 3px;
}
.project_hover_effect:hover {
  background-color: #F2F2F2;
  width: 90%;
}
.project_hover_effect .edit_pencil {
  display: none;
}
.project_hover_effect:hover .edit_pencil {
  width: 19px;
  height: 16px; 
  display: block;
  position: absolute;
  float: right;
  right: 0;
  background-color: #F2F2F2;
  cursor: pointer;
}

.project_hover_effect_description {
  position: relative;
}
.project_hover_effect_description:hover {
  background-color: #F2F2F2;
  width: 100%;
  border: 1px solid #DFDFDF;
}
.project_hover_effect_description .edit_pencil {
  display: none;
}
.project_hover_effect_description:hover .edit_pencil {
  width: 19px;
  height: 16px;
  display: block;
  position: absolute;
  float: right;
  right: 0;
  top: 5px;
  cursor: pointer;
  background-color: #F2F2F2;
}
.billable_select {
  min-height: 30px;
  min-width: 230px;
  display: flex;
  align-items: center;
}
.billable_select:hover {
  width: inherit;
}

.project_name {
  min-height: 30px;
  min-width: 50px;
  display: flex;
  align-items: center;
}
.project-name-slash {
  margin: 0 5px 0 5px !important;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 16px;
}
.project_name:hover {
  width: inherit;
}
#Aliases {
  color: #828282;
}
.max-width-name {
  min-width: 150px;
}
.change_superimpos {
  padding: 0 20px 0 0;
}

.clear-datapicker-svg-projects div .clear-svg-datapicker{
  right: 22px;
  top: 8px;
}

.project-info__main-info__row__block .multi-value-suggestbox__container{
  display: flex;
  width: 90%;
  padding-top: 0px;
  z-index: 100;
}

.project-info__main-info__row__block .multi-value-suggestbox__container div .multi-value-suggestbox__control{
  max-height: 80px;
  overflow-y: auto;
}

.main-block__child--heigth{
  z-index: 3;
  height: 99%;
}

.client_details {
  z-index: 10;
}