.toolbar-container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
}

.toolbar-container.without-search > .filters-menu::after{
    left: 32px;
}

.toolbar-container__main-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.projects-amount {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: 60px;
    padding: 0 0 25px 0;
}

.details-toolbar {
    height: 60px;
    padding: 15px 0;
}

.left-toolbar-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-cont {
    position: relative;
}

.search-field+.list-tool-bar__input-svg--position {
    position: absolute;
    top: 14%;
    right: 1%;
    transition: 0.3s;
}

.search-field {
    width: 450px;
    outline: none;
    border: 1px solid #DFDFDF;
    padding: 8px 50px 8px 15px;
    font-size: 14px;
    transition: 0.3s;
}

.search-field.with-btn {
    padding-right: 90px;
}

.search-actions {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.search-action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: auto !important;
    padding: 0 8px !important;
    border-radius: 0 !important;
    transition: none !important;
}

.search-action:not(.disabled) {
    cursor: pointer;
}

.search-action .MuiTouchRipple-root {
    display: none;
}

.search-action:not(.disabled):hover,
.search-action:not(.disabled):focus {
    background-color: transparent !important;
}

.search-action:active {
    opacity: 0.75;
}

.search-action:not(.disabled):hover svg path,
.search-action:not(.disabled):focus svg path {
    fill: #f26939;
}

.search-action-search {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 8px;
    background-color: #f4f4f4;
    transition: 0.3s;
}

.search-action-search:not(.disabled):hover,
.search-action-search:not(.disabled):focus {
    background-color: #fef0eb !important;
}

.search-action-search svg path {
    fill: rgb(165, 165, 165);
}

.search-action-search.active-action {
    background-color: #fef0eb !important;
}

.search-action-search.active-action svg path {
    fill: #f17e55;
}

.search-field:focus {
    border: 1px solid #f26939;
}

.search-field::-webkit-input-placeholder {
    color: #b0b0b0;
}

.search-field::-moz-placeholder {
    color: #b0b0b0;
}

.search-field:-ms-input-placeholder {
    color: #b0b0b0;
}

.search-field:-moz-placeholder {
    color: #b0b0b0;
}

.filter-options {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filters {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    margin: 0 0 0 30px;
}

.filters:hover path {
    fill: #D95E33;
}

.configuration {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 0 0 0 20px;
}

.configuration path {
    transition: 0.2s;
}

.configuration:hover path, .i-active path {
    fill: #D95E33;
}

.configuration-options-cont {
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 180px;
    min-height: auto;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.14);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.14);
    font-size: 14px;
    z-index: 999;
    top: 120%;
    left: 0%;
}

.configuration-option {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px !important;
    padding: 0 15px;
    user-select: none;
    margin: 0;
    transition: 0.3s;
}

.configuration-option:hover {
    background: #fff3e5;
}

input[type="checkbox"]:disabled + .configuration-option__checkbox,
input[type="checkbox"]:disabled + .configuration-option__checkbox:hover {
    opacity: 0.75;
    background-color: rgb(226, 226, 226);
}

.configuration-option__checkbox {
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: white;
    border: 1px solid #BDBDBD;
    cursor: pointer;
    transition: 0.3s;
}

.configuration-option__label {
    margin-left: 30px;
    display: block;
    flex-shrink: 0;
    white-space: nowrap;
    cursor: pointer;
}

.right-toolbar-part {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.toolbar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    height: 100%;
    cursor: pointer;
    user-select: none;
    padding: 0 10px;
    border: 1px solid transparent;
    transition: 0.3s;
}

.toolbar-button svg {
    margin: 0 10px 0 0;
    transition: 0.3s;
}

.toolbar-button:hover {
    border-color: #f26939;
}

.toolbar-button-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    height: 100%;
    cursor: pointer;
    user-select: none;
    padding: 0 10px;
    border: 1px solid transparent;
    transition: 0.3s;
}

.toolbar-button-disabled svg {
    margin: 0 10px 0 0;
    transition: 0.3s;
}

.filters-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    width: 100%;
    background: #F8F8F8;
    transition: 0.3s;
    box-shadow: 1px 2px 6px rgba(0,0,0,0.3);
}

.filters-menu::after {
    content: '';
    display: block;
    position: absolute;
    border-bottom: 10px solid #F8F8F8;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    left: 482px;
    top: -10px;
    filter: drop-shadow(0 -1px 1px rgba(0,0,0,.1));
}

.hide-config {
    visibility: hidden;
    opacity: 0;
}

.hide-filters {
    height: 0;
    visibility: hidden;
    opacity: 0;
    display: none;
}

.filter-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.filter-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.filter-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 35px;
    font-size: 16px;
    margin: 20px 20px 20px 0;
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
}

.apply {
    color: white;
    background: #F26939;
}

.apply:hover {
    background: #D95E33;
}

.reset:hover {
    background: #DFDFDF;
}

.choosed-filters {
    position: absolute;
    top: -3px;
    right: -3px;

}

.toolbar-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 16px 20px;
    height: 100%;
    cursor: pointer;
    user-select: none;
    padding: 0 10px;
    border: 1px solid transparent;
    transition: 0.3s;
}
.checkbox_extend_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
}
.checkbox_extend_container>i {
    margin-left: 10px;
    cursor: pointer;
}
.checkbox_extend {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;

}
.checkbox_extend + label {
    position: relative;
    padding: 0 0 0 45px;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
}
.checkbox_extend + label:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 34px;
    height: 14px;
    border-radius: 13px;
    background: #CDD1DA;
    box-shadow: inset 0 2px 3px rgba(0,0,0,.2);
    transition: .2s;
}
.checkbox_extend + label:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    transition: .2s;
}
.checkbox_extend:checked + label:before {
    background: #F26939;
    opacity: 0.5
}
.checkbox_extend:checked + label:after {
    left: 18px;
    background: #F26939;
}
.popover {
    width: 223px;
    height: 67px;
    display: absolute;
    margin: 100px 0 0 -90px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.14);
    background: #FFEEDF;
    border: 1px solid #A5A5A5;
    z-index: 2;
    cursor: pointer;
}
.popover p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    color: #727272;
    margin: 13px 0 0 16px;
}
.runner-container {
    margin-left: 40px;
}
.toolbar-date_range {
    width: 600px;
    display: block;
}
.toolbar-date_range-container {
    display: inline-block;
    margin-left: 10px;
}
.history_date {
    min-height: 42px;
}
.history_date>div {
    width: 250px;
    height: 35px;
}
.history .clear-svg {
   top: 18px;
   right: 20px;
}
.button-disabled {
    cursor: default !important;
    pointer-events: none !important;
    background-color: #E4E4E4!important;
}

.tooltip-roles--text {
    display: flex;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    margin: 2px 4px;
}

.tooltip-roles--text > div {
    position: absolute;
    margin-top: 15px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.14);
    background: #FFEEDF;
    border: 1px solid #A5A5A5;
    max-width: 350px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    color: #727272;
    cursor: text;
    padding: 0 10px;
}

.sync-buttons-group {
    display: flex;
    height: 100%;
}
