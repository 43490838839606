.c-role-modal__block {
  padding-bottom: 35px;
  border-bottom: 1px solid #D7D7D7;
}

.c-role-modal__block__row {
  padding: 20px 0 0px;
}

.c-role-modal__title {
  font-weight: bold;
}

.c-role-modal__margin-right30 {
  margin-right: 15px !important;
}

.c-role-modal__form input {
  padding: 0 0 0 20px ;
}

.c-role-modal__form>.c-role-modal__input input#rate {
  padding: 0 10px;
}

.c-role-modal__form p {
  margin: 0 -10px 0 10px;
}

.c-role-modal__form .clear-svg {
  right: 7%;
}

.c-role-modal__date-picker .material-icons {
  transform: scale(0.9);
}

.c-role-modal__date-picker button {
  padding: 5px;
}

.employee__date-picker .material-icons {
  transform: scale(0.9);
}

.employee__date-picker button {
  padding: 5px;
}

.employee__date-picker>div:after {
  content: none !important;
}
.employee__date-picker>div {
  width: 90%;
  height: 35px;
  padding-right: 10px;
}

.c-role-modal__select>div>div {
  padding: 8px 20px;
}

.c-role-modal__input>div:after, .c-role-modal__select:after, .c-role-modal__date-picker>div:after {
  content: none !important;
}

.c-role-modal__form>div>div {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  font-size: 14px;
}

.c-role-modal__input>div, .c-role-modal__select>div, .c-role-modal__date-picker>div {
  width: 100%;
  height: 35px;
}

.c-role-modal__list {
  padding: 15px 0 0 0;
}

.c-role-modal__add-new-container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 19px;
}

.c-role-modal__add-new {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 0 0 20px; */
  height: 35px;
  cursor: pointer;
  user-select: none;
  padding: 0 10px;
  border: 1px solid transparent;
  transition: 0.3s;
}

.c-role-modal__add-new span {
  line-height: 24px;
}

.c-role-modal__add-new svg {
  margin: 0 10px 0 0;
  transition: 0.3s;
}

.c-role-modal__add-new:hover {
  border-color: #f1d5ca;
}

.c-role-modal-blocked__add-new {
  color:#b3aba9;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 0 0 20px; */
  height: 35px;
  cursor: pointer;
  user-select: none;
  padding: 0 10px;
  border: 1px solid transparent;
  transition: 0.3s;
}

.c-role-modal-blocked__add-new span {
  line-height: 24px;
}

.c-role-modal-blocked__add-new svg {
  margin: 0 10px 0 0;
  transition: 0.3s;
}


.c-modal .buttons {
  justify-content: flex-end;
  margin: auto 0 0 0;
}

.left_p p {
  margin-left: 0 !important;
}

/*my*/

.emploue-role-block2{
  position: absolute;
  height: 0px;
  left: 254px;
  top: 386px;
}

.title-border {
  border-bottom: 1px solid #D7D7D7;
  min-height: 44px;
  height: 44px;
  margin-bottom: 8px;
}

.role-page-project__form {
  width: 350px !important;
  flex-grow: 0;
}

.role-page-project__bytton{
  width: 50px !important;
  flex-grow: 0;
}

.role-page-project__form>.c-SOW-edit__input input#rate {
  padding: 0 10px;
}

.role-page-project__form  p {
  margin: 0 -10px 0 20px;
}

.role-page-project__form  .clear-svg {
  right: 7%;
}

.role-page-project__form>div>div {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  font-size: 14px;
}
.role-page-project__form-150 {
  width: 150px !important;
  flex-grow: 0;
}

.role-page-project__form-150  p {
  margin:  0 -10px 0 10px;
}

.role-page-project__form-150  .clear-svg {
  right: 7%;
}

.role-page-project__form-150>div>div {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  font-size: 14px;
}

.role-page-project-radio__form-150 {
  width: 350px !important;
  flex-grow: 0;
}

.role-page-project-radio__form-150 input {
  padding: 0 0 0 20px;
}

.role-page-project-radio__form-150>.c-SOW-edit__input input#rate {
  padding: 0 10px;
}

.role-page-project-radio__form-150  p{
  margin:0 -10px 0 20px;
}

.role-page-project-radio__form-150  .clear-svg {
  right: 7%;
}

.role-page-project-radio__form-150>div>div {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  font-size: 14px;
}

.role-button{
  position: absolute;
width: 115px;
height: 19px;
left: 1035px;
top: 445px;
}

@media only screen and (max-width: 1250px) {
  .role-button{
    position: absolute;
  width: 150px;
  height: 24px;
  left: 800px;
  top: 445px;
  }
}


.margin-down-role {
  margin-top: 10px !important;
}

.MuiRadio-colorSecondary-2154.MuiRadio-checked-2151{
  color: #f26939 !important;
}
.MuiRadio-colorSecondary-156.MuiRadio-checked-153 {
  color: #f26939 !important;
}

.radio-margin-left{
  margin-left: 45px !important;;
}

.role-page-project__form-150 label span{
  color: #f26939 !important;
}

.role-page-project__form label span{
  color: #f26939 !important;
}

.c-role-modal__input label span{
  color: #f26939 !important;
}

.role-page-project-radio__form-150 div label span span svg path{
	color: #f26939 !important;
}

.employye-name-error{
  margin-top:35px;
}

.c-role-modal__date-picker p{
  margin-bottom: -13px;
  margin-left: 0px !important;
}


.color-black-role{
  color: black,
}

.project-input-error-margin{
  margin-top:3px !important;
}

.margin-role-new-status{
  margin-right: 40px;
}

.margin-role-new-deta{
  margin-left: 20px;
}

.margin-role-new-end-date{
  margin-left: 10px;
}

.role-status-error{
      color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 8px;
    min-height: 1em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1em;
}

.dropdown-select-blocked div .css-1wy0on6{
  height: 30px;
}

.role-svg-options-column:hover {
  background: #E5DACE;
}

.role-edit-svg-options{
  margin-left: 9px;
}

.role-page__role-type-svg--margin div svg{
  margin-left: 45px;
}

.role-page__role-type-svg--margin div div div{
  padding: 8px 30px 8px 15px;
}

.role-modal__comment--width-wraper .role-page-project__form .clear-svg{
  right: 5px;
}

[class*="role-page-project__form"] .MuiInputBase-input {
  padding-left: 10px;
  padding-right: 10px;
}

.button.button-apply-and-create {
  width: 170px;
}