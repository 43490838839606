.employees-form__row{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.employees-form__standart-container {
    height: 55px;
    width: 200px;
    margin: 8px;
}

.employees-form__standart-container div div .clear-svg {
    top: 10px;
}

.field--not--editable {
    position: relative;
    border: 1px solid transparent;
}

.delete-button-container {
    display: flex;
    justify-content: flex-end;
}

.delete-button {
    width: 120px;
}

.delete-button:hover,
.delete-button:focus {
    background: #F2F2F2;
}

.change-status-dialog--confirmation {
    display: flex;
    align-items: center;
}

.change-status-dialog--date {
    max-width: 150px;
}