.placeholder--is-disabled {
  cursor: default !important;
}

.placeholder--is-disabled > div {
  background: #D9D9D9 !important;
  cursor: default !important;
}

.placeholder--is-disabled > div > input {
  cursor: default !important;
}

placeholder-datapicker-is-active > div {
  margin-top: -5px;
}

.placeholder-datapicker-is-disabled {
  cursor: default !important;
}

.placeholder-datapicker-is-disabled > div {
  background: #D9D9D9 !important;
  cursor: default !important;
}

.placeholder-datapicker-is-disabled > div > input {
  cursor: default !important;
}

.clear-svg-suze-datapicker {
  position: absolute;
  top: 50%;
  right: 1%;
  cursor: pointer;
}

.svg-calendar-add-info .clear-svg-suze-datapicker {
  margin-right: 45px;
}

.clear-svg-datapicker{
  margin-right: 23px;
}