.no-edit-field__label--question-icon {
    margin-left: 5px;
}

.no-edit-field__label {
    display: flex;
    flex-direction: row;
}
  
.label__question-icon--position {
    position: relative;
}
  
.no-edit-block__filter-button--hint-body {
    left: -20%;
    z-index: 10;
}

.filter-button_hint-body {
    width: 250px;
    height: 70px;
    position: absolute;
    margin: 0 0 0 140px;
    top: -5%;
    font-size: 12px;
    padding: 6px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    color: #727272;
    box-shadow: 2px 2px 2px rgba(0,0,0,.14);
    background: #ffeedf;
    border: 1px solid #a5a5a5;
}

.no-editable-block--block-margin {
    margin-top: 10px;
}

.no-editable-block--array {
    display: flex;
    flex-direction: column;
}