.client-new-margin{
    margin-top: -25px;
}

.client-button-state2{
    margin-top: 15px;  
}

.client-add-error-p-step1{
    margin-left: 10px;
}

.create-client-button-contact-step2{
    margin-top: -25px;
    margin-left:78%; 
}

.buttons-bottom-contacts{
    margin-top: 25px;
    margin-right: 40px;
}

.step__input-client>div, .step__select>div, .step__date-picker>div, .step__search-dropdown {
    width: 350px;
    height: 35px;
    min-height: 35px;
}
.step__input-client input::-ms-clear {
    display: none;
}
.client-new-margin div {
    margin-right: 25px;
}