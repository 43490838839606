.multi-select__relative-container {
  width: 400px;
  position: relative;
}

.multi-select__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  width: 350px;
  margin: 20px;
}

.multi-select__label>span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  color: #828282;
  width: fit-content;
}

.multi-select__label {
  flex-direction: row;
}

.multi-select_is-required {
  color: orangered !important;
}

.multi-select__text-field {
  display: flex;
  align-self: center;
  align-items: center;
  height: 35px;
  width: 100%;
  background: white;
  border: 1px solid #dfdfdf;
  padding: 0 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
  position: relative;
}

.multi-select__text-field>span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-select__text-field svg {
  position: absolute;
  top: 15px;
  right: 10px;
  transform: scaleX(0.8);
}

.multi-select__disabled {
  background-color: rgb(217, 217, 217) !important;
  cursor: default;
}

.multi-select__options-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  background: white;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  min-height: max-content;
  height: auto;
  visibility: hidden;
  opacity: 0;
  transition: .2s;
  padding: 8px 0;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 999;
}

.multi-select__options-container>label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  color: #828282;
  transition: 0.3s;
  height: 46px !important;
  padding-left: 10px;
}

/* TODO for Sasha */
.multi-select__options-container>label:hover {
  background: transparent;
}

.multi-select__expanded {
  visibility: visible;
  opacity: 1;
}
.multi-select__expanded > label > label {
  height: 20px;
}

.multi-select__text {
  color: black !important;
}

.edited_offices {
  width: 200px !important;
  height: 30px;
  margin: -10px 0 0 0 !important;
}
.edited_offices .multi-select__label {
  display: none;
}

.multi-select__input-tabindex--not-display {
  width: 0;
  height: 0;
  border: 0;
  visibility: hidden;
}

.multi-select__input-tabindex--not-display div div:focus {
  background: none;
}

.multi-select__list-cell {
  overflow: inherit !important;
}

.multi-select--inline {
  width: 300px;
  display: flex;
  align-items: center;
}

.multi-select--inline .multi-select__text-field {
  max-width: 190px;
}

.multi-select--inline .multi-select__label {
  height: auto;
  white-space: nowrap;
  margin-right: 1rem;
}

.multi-select--inline .multi-select__options-container {
  top: 100%;
  right: -10px;
}