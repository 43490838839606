.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 40px;
  width: 100%;
  /* margin: 40px 0; */
}

.start-point {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.progress-bar-circle {
  width: 15px;
  height: 15px;
  background: #F2F2F2;
  border-radius: 50%;
  /* z-index: 2; */
  transition: 0.3s;
}

.progress-bar-border {
  position: absolute;
  top: 6px;
  left: 5px;
  width: 100%;
  height: 3px;
  z-index: -1;
  background: #F2F2F2;
  transition: 0.3s;
}

.progress-bar-label {
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  position: absolute;
  top: -25px;
}

.active {
  background: #F26939;
}

.not-first {
  position: absolute;
  right: -15px;
}
