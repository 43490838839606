.billing-details {
    margin-top: 10px;
    margin-bottom: 10px;
}

.billing-details-container {
    display: flex;
    flex-direction: row;
}

.billing-details-block-data {
    margin-top: 5px;
}

.billing-header {
    border-right: 1px solid grey;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 5px;
    margin-right: 5px;
}

.billing-header__status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 33px;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.billing-role-cell {
    justify-content: center !important;
}

.billing-justification-header {
    display: flex;
    align-items: center;
    margin: 12px 0 12px 10px;
    position: relative;
}

.billing-invoice-header {
    display: flex;
    align-items: center;
    margin: 22px 0 22px 10px;
    position: relative;
}

.billing-justification-header-title {
    font-size: 15px;
    margin-right: 10px;
}

.billing-justification-add-button {
    position: absolute;
    right: 10px;
    height: 30px;
}

.billing-justification-other-header {
    margin-top: 20px;
}

.justification__modal-width {
    height: auto;
}

.justification__modal-width div div{
    overflow-y: visible;
    max-width: 870px;
}

.justification__modal-width div div .justification__dialog{
    padding: 0 24px 0 24px !important;
}

.other-justification__modal-width {
    height: auto;
}

.other-justification__modal-width div div{
    overflow-y: visible;
    max-width: 590px;
}

.other-justification__modal-width div div .justification__dialog{
    padding: 0 24px 0 24px !important;
}

.redux-form-justification-width {
    width: 870px;
}
.justification-modal-body {
    margin: 30px 0 0 0;
}
.redux-form__justification-modal-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}

.redux-form__justification-modal-row--create {
    min-height: 80px;
    width: 815px;
}

.redux-form__label--justification {
    margin-bottom: 4px;
}
.justification-modal--title div div {
    height: 40px !important;
}
.justification-text_field {
    min-height: 42px;
}

.justification-text_field>div {
    width: 250px;
    height: 35px;
    max-width: 250px !important;
}

.cdp-form-file {
    margin-left: 10px;
    font-size: 14px;
}

.form__justification-file-block .cdp-form-file .input_type-file{
    max-width: 250px;
}

.justification-modal--dialog-action  {
    margin: 10px 0 10px 0;
}

.justification-billable-checkbox {
    margin-top: 17px;
    margin-bottom: 13px;
}

.justification-suggestbox-field .multi-value-suggestbox__container div .multi-value-suggestbox__control {
    margin-top: 5px !important;
}

.justification-other-full-width-field {
    max-width: 850px !important;
}

.justification-other-redux-form__label {
    margin-bottom: 10px;
}

.justification-big-text-field div {
    padding: 0;
}

.justification-big-text-field textarea{
    max-height: 65px;
    overflow: auto;
    padding: 10px 0;
}

.justification-suggestbox-field .multi-value-suggestbox__container  {
    font-size: 14px;
}

.normal_color {
    color: rgb(0, 160, 0);
}

.error_color {
    color: red;
}

.billing-multi-box {
    width: 300px;
    height: 40px;
}

.billing-header-block {
    height: 40px;
}

.billing-tree-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

.MuiTreeView-root {
    margin: 5px !important;
    padding: 0;
    max-width: 100% !important;
}

.checkbox-cont-tree {
    position: relative;
    height: 16px;
    margin: 0 20px 0 -15px !important;
    width: 0 !important;
}

/* .MuiTreeItem-content:focus {
	outline: none !important;
}

.MuiTreeItem-iconContainer:focus {
	outline: none !important;
}

.MuiTreeItem-iconContainer div:focus {
	outline: none !important;
}

.billing-tree-label:focus {
	outline: none !important;
} */

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background: none;
}