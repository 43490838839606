.placeholder--is-active>div {
  color: #999;
}

.placeholder--is-disabled>div {
  background: #D9D9D9;
}

.step__select {
  padding-top: 5px;
}

.step__date-picker {
  margin-top: -5px !important;
}

.skills-level-select {
  width: 150px;
  margin-top: -20px !important;
}

.placeholder-datapicker-is-active {
  margin-top: 0px;
}

.placeholder-datapicker-is-disabled {
  margin-top: 0px;
}

.placeholder-datapicker-is-active>div> {
  color: #999;
}

.placeholder-datapicker-is-disabled>div> {
  background: #D9D9D9;
}

.placeholder-datapicker-is-active>div> {
  color: #999;
}

.placeholder-datapicker-is-disabled>div> {
  background: #D9D9D9;
}

.skills-level-select_cell-list {
  width: 150px;
}