input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #333333;
  -webkit-box-shadow: 0 0 0px 1000px #F2F2F2 inset;
  margin: 1px;
}

.page-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 25px;
  overflow: auto;
}

.page-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  height: 120px;
  margin: 0 0 15px 0;
}

.back-nav-container {
  display: flex;
  margin: 0 0 20px 0;
}

.back-button-wrapper {
  display: flex;
}

.back-button {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  width: max-content;
  height: max-content;
  line-height: 14px;
  margin: 0 10px 0 0;
}

.back-button svg {
  margin: 0 10px 0 0;
}

.page-header {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding: 0 0 10px 0;
}

.page-header span {
  font-size: 16px;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 120px);
}

.step__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* flex-shrink: 0; */
  /* padding: 50px 0 0 0; */
  min-height: 70px;
}

.description-row {
  align-items: flex-start;
  height: 150px;
  /* height: auto; */
}

.description-row>div {
  max-width: 1150px;
}

.step__row.border {
  border-bottom: 1px solid #D7D7D7;
}

.step__input>div {
  border-bottom: 1px solid #D7D7D7;
}

.second-row {
  padding: 0;
}

.scrollbars__add-project {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  height: calc(100% - 50px) !important;
}

.title {
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  height: 35px;
}

.multi-select__container.step__multiselect {
  display: flex;
  width: 350px;
  margin: 0 50px 0 0 !important;
  padding: 0;
}

.multi-select__label {
  height: 15px;
  line-height: 14px;
}

.step__multiselect .multi-select__text-field {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  font-size: 14px;
  height: 35px;
}

.step__form {
  margin: 0;
  min-height: 50px;
  justify-content: flex-end;
}

.step__input>label>span,
.step__form>label>span,
.step__date-picker>label>span,
.step__multiselect .multi-select_is-required {
  color: orangered !important;
}

.c-SOW-edit__description>label>span,
.step__form>label>span,
.step__date-picker>label>span,
.step__multiselect .multi-select_is-required {
  color: orangered !important;
}

.step__description>label>span,
.step__form>label>span,
.step__date-picker>label>span,
.step__multiselect .multi-select_is-required {
  color: orangered !important;
}

.step__form>div>div:before, .step__form>div>div:after {
  content: none;
}

.step__form input {
  width: 100%;
  height: 100%;
  padding: 0 40px 0 10px;
}

.step__form .date-svg {
  position: absolute;
  cursor: pointer;
  top: 45%;
  right: 16%;
}

.step__form>div>div {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
  font-size: 14px;
}

.step__input>div, .step__select>div, .step__date-picker>div, .step__search-dropdown {
  width: 350px;
  height: 40px;
  min-height: 40px;
}

.edit-SOW__select>div {
  display: flex;
  align-items: center;
  width: 340px !important;
  padding: 0 0 0 10px !important;
  font-size: 14px;

}

.step__select>div {
  display: flex;
  align-items: center;
  width: 340px !important;
  padding: 0 0 0 10px !important;
}

.step__input>div>div>p {
  margin: 2px -20px 0 20px;
}

.file-uploader-container {
  display: flex;
  flex-direction: column;
}

.step__input, .step__select, .step__date-picker, .step__search-dropdown {
  margin-right: 50px !important;
}

.step__search-dropdown>div:last-child {
  background: white;
}

.step__search-dropdown>div:nth-child(1), .step__search-dropdown>div:nth-child(2) {
  background: #F2F2F2;
  min-height: 35px;
  height: 100%;
}

.step__select>div>div {
  padding: 7px 32px 6px 20px;
}

.step__date-picker>div, .step__date-picker input {
  cursor: pointer;
}

.step__form input::placeholder,
.placeholder--is-active>div>div,
.step__multiselect .multi-select__text-field {
  color: #999;
  opacity: 1;
}

.step__date-picker>p {
  margin-bottom: -20px;
}

.step__description {
  margin: 20px 0 0 0 !important;
}
.details_description {
  margin: 0;
}
.details_description>div {
  padding: 0 0 0 20px;
  height: 180px;
  box-sizing: border-box;
}

.details_description>div>div {
  height: 100%;
  padding: 10px 0;
}

.details_description textarea {
  height: 150px !important;
  box-sizing: border-box;
  overflow: auto;
}

.step__description>div {
  padding: 0 0 0 20px;
  height: 180px;
  box-sizing: border-box;
}

.step__description>div>div {
  height: 100%;
  padding: 10px 0;
}

.step__description textarea {
  height: 150px !important;
  box-sizing: border-box;
  overflow: auto;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-shrink: 0;
  font-size: 16px;
  height: 50px;
}

.right-side-buttons {
  display: flex;
  justify-content: space-between;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  transition: 0.3s;
  margin: 0 0 0 20px;
  cursor: pointer;
  user-select: none;
}

.button-create-roles {
  width: 150px;
}

.btn-disabled {
  cursor: default !important;
  /* pointer-events: none !important; */
  background-color: #E4E4E4!important;
}

.next {
  color: white;
  background: #F26939;
}

.next:hover {
  background: #D95E33;
}

.cancel:hover {
  background: #F2F2F2;
}

.save-and-close {
  color: white;
  background: #F26939;
  width: 150px;
  margin: 0;
}

.save-and-close:hover {
  background: #D95E33;
}


.last-list-child {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.c-list-arrows {
  position: absolute;
  top: 15%;
  left: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 10px;
  width: 10px;
  height: 12px;
}

.step-3 {
  margin-left: auto;
}

.documents-container {
  height: auto;
  min-height: 100px;
}

.project-input-error{
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1em;
  position: absolute;
}

.svg_select_center {
  top: 30px !important;
}
.adornment_input input {
  padding: 0 40px 0 20px;
}

.title__margin-step2-po{
  margin-top:20px;
}

.add-document__button--margin {
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer !important;
  max-width: 140px;
  margin-top: 10px;
  padding-left: 3px;
}

.add-document__button--margin>i>svg {
  margin: 0 10px 0 0;
}

.documents-container__block--padding {
  padding-bottom: 35px;
}
.step__input input::-ms-clear {
  display: none;
}

.step__multi-suggest-box {
  width: 350px;
  min-height: 35px;
}

.step__multi-suggest-box>div:last-child {
  background: white;
}

.step__multi-suggest-box>div:nth-child(1), .step__multi-suggest-box>div:nth-child(2) {
  min-height: 35px;
  height: 100%;
}

.multi-box-wrapper {
  width: 100%;
  margin-right: 50px;
}

.multi-sagges-box__size {
  width: 350px !important;
}

.multi-box-wrapper .multi-value-suggestbox__container .css-bg1rzq-control{
  max-height: 60px;
  margin-top: 4px;
}

.multi-box-wrapper .multi-value-suggestbox__container .css-1pcexqc-container .multi-value-suggestbox__control--is-focused{
  max-height: 60px;
  margin-top: 4px;
}

.multi-box-wrapper .multi-value-suggestbox__container .css-1pcexqc-container .multi-value-suggestbox__control--is-open{
  max-height: 60px;
  margin-top: 4px;
}

.multi-box-wrapper .multi-value-suggestbox__container{
  display: flex;
  width: 100%;
  padding-top: 0px;
  z-index: 100;
}

.multi-box-wrapper .multi-value-suggestbox__container div .multi-value-suggestbox__control{
  max-height: 60px;
  overflow-y: auto;
}

.step__input-buget--index div div p{
	z-index: 2;
}

.create-project__search-dropdown--disabled .placeholder--is-disabled div div {
  height: 17px;
}

@media screen and (max-width: 1440px) {
  .media_z-index_create-project {
    z-index: 50;
  }
}

.step__input-renewal-status {
  min-height: 38px;
  height: 280px;
}

.step__input--with-adornment>div>div>p {
  margin: 2px -10px 0 15px;
}