.c-list {
  display: flex;
  flex-direction: column;
}

.inner-list {
  border: 1px solid #D7D7D7;
  margin: 10px 20px 10px 40px;
}

.c-list__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  min-height: 40px;
  border-bottom: 1px solid #D7D7D7;
  font-style: italic;
  font-size: 13px;
  color: #4B4B4B;
  background: rgb(255, 247, 237);
}
.hide_header-border {
  border-bottom: none;
  border-top: 1px solid #D7D7D7;
}

.c-list__header-col {
  display: flex;
  align-items: center;
  flex-basis: 150px;
  padding: 0 10px;
  overflow: hidden;
  flex-grow:1;
  height: 100%;
  min-height: 35px;
  white-space: pre-wrap;
}
.c-list__column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-basis: 150px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
}

.c-list__header-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-list__header-name--no-arrows {
  padding-right: 0;
}

.c-list__arrows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 10px;
  height: 13px;
  padding-left: 15px;
}

.c-list__arrows svg {
  transform: scale(1.3)
}

.delete-arrow {
  display: none
}
.list__header-col-overflow > span {
  overflow: hidden !important;
}

.without-width-col {
  min-width: initial;
}

.huge-col {
  max-width: 1100px;
}

.biggest-col {
  max-width: 350px;
}

.big-col {
  max-width: 250px;
}

.medium-col {
  max-width: 150px;
}

.small-col {
  max-width: 120px;
}

.small-col .c-list__header-name  {
  max-width: 80px;
}

.tiny-col {
  max-width: 25px;
  width: 25px;
}

.xs-col {
  max-width: 80px;
}
.smallest-col {
  max-width: 55px;
}

.end-date-col-with-warn {
  min-width: 105px;
}

.sow-po-col-with-warn {
  min-width: 90px;
}

.col-employee-data  .c-list__header-name{
  margin-left: 0;
  max-width: 60px;
}

.c-list__status {
  max-width: 120px;
}

.c-list__status--with-error {
  box-shadow: 0 0 0px 1px #fce6e6, 0 0 2px 2px #f00;
}

.c-list__status span {
  padding: 5px 15px;
  /*color: white;*/
  border-radius: 5px;
  white-space: nowrap;
}

.c-list__status svg {
  cursor: pointer;
}

.c-list__options-column {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin: 0 10px 0 0;
  border-radius: 50%;
  background: transparent;
}

.c-list__options-column__svg:hover {
  background: #E5DACE;
}

.action-lable{
  right: 2.5%;
}

.list__no-item--border{
  min-height:50px;
  padding: 17px;
  font-size: 13px;
  color: #4B4B4B;
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
}
.header_col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.header_col_has_border {
  border-right: 1px solid #D7D7D7;
}
.header_col_hasnt_border {
  border-right: none;
}
.show_border-bottom {
  border-bottom: 1px solid #D7D7D7;
}
.shift_up-header {
  transform: translateY(-50%);
}

.fixed-wrapper_c-list__header {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  color: #4B4B4B;
  background: #FFFFFF;
}

@-moz-document url-prefix() {
  .fixed-wrapper_c-list__header {
      width: calc(100% - 18px);
  }
}

.page-container_for-projected-revenue,
.page-container_for-accounting {
  display: inline-flex;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
}

.page-container_for-projected-revenue .fixed-wrapper_c-list__content > .c-list__item-cont:nth-last-child(1),
.page-container_for-projected-revenue .fixed-wrapper_c-list__content > .c-list__item-cont:nth-last-child(2) {
  background-color: #fff3e5;
}

.page-container_for-projected-revenue .fixed-wrapper_c-list__header,
.page-container_for-projected-revenue .fixed-wrapper_c-list__content,
.page-container_for-accounting .fixed-wrapper_c-list__header,
.page-container_for-accounting .fixed-wrapper_c-list__content {
  display: table;
  width: 100%;
}


.page-container_for-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

.page-container_for-scroll .c-list__header,
.page-container_for-scroll .c-list__item-cont {
  padding-right: 17px;
}

.page-container_for-scroll::-webkit-scrollbar {
  display: none;
}

.custom_scrollbar-container {
  position: relative;
  z-index: 1;
}

.custom_scrollbar-container>div {
  overflow: hidden !important;
}

.header-title__fixed-width div span {
  width: 52px;
}
.white-space_nowrap {
  white-space: nowrap;
}

.file-cell-block {
  display: flex;
}

.cell-file-title {
  font-size: 11px;
  text-decoration: underline;
  color: blue;
}

.c-list__item-column--datepicker.fixed-width {
  min-width: 168px;
}