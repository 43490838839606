.checkbox__container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  margin: 0 50px 0 0;
}

.checkbox__container>label{
  cursor: pointer;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Normal Track */

input[type="checkbox"].ios-switch+div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transition-duration: .4s;
  -webkit-transition-property: background-color, box-shadow;
  box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0.4);
  /* margin: 15px 1.2em 15px 2.5em; */
}


input[type="checkbox"]:disabled.ios-switch+div {
  opacity: 0.75;
}
/* Normal Knob */

input[type="checkbox"].ios-switch+div>div {
  float: left;
  width: 18px;
  height: 18px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(.54, 1.85, .5, 1);
  -webkit-transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color, box-shadow;
  -moz-transition-timing-function: cubic-bezier(.54, 1.85, .5, 1);
  -moz-transition-duration: 0.4s;
  -moz-transition-property: transform, background-color;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

/* Green Track */

input[type="checkbox"].green.ios-switch:checked+div {
  background-color: #f26939;
  border: 1px solid #f26939;
  box-shadow: inset 0 0 0 10px #f26939;
}

/* Green Knob */

input[type="checkbox"].green.ios-switch:checked+div>div {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px #f26939;
}

/* Checked Knob (Blue Style) */

input[type="checkbox"].ios-switch:checked+div>div {
  -webkit-transform: translate3d(20px, 0, 0);
  -moz-transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px rgba(8, 80, 172, 1);
}
