.clients-name-error {
  margin-top: 20px;
}
.client-info__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 25px 0;
  height: 50px;
}

.working-period-restriction__dialog-width div div {
  overflow-y: visible;
  max-width: 1000px;
}
