.edit-SOW__simple-container {
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 330px;
  margin: 10px;
}

.edit-SOW__item-form {
  height: 35px;
  flex-grow: 0;
}

.redux-form__SOW--label {
  display: flex;
  align-items: center;
  color: #828282;
  font-size: 12px;
  height: 15px;
}

div.edit-SOW__text-field,
div.edit-SOW__select,
div.edit-SOW__date-picker {
  height: 100%;
}

div.edit-SOW__text-field,
div.edit-SOW__select,
div.edit-SOW__date-picker,
.edit-SOW__search-dropdown > span + div {
  background: #F2F2F2;
  border: 1px solid #DFDFDF;
}

div.edit-SOW__search-dropdown>div {
  border-radius: 0;
  min-height: 35px;
  border-color: transparent !important;
  --box-shadow-color: #DFDFDF;
  box-shadow: 0 0 0 1px var(--box-shadow-color);
}

div.edit-SOW__search-dropdown>div>div:nth-child(2)>div {
  padding: 0 8px;
}

.edit-SOW__text-field input, .edit-SOW__date-picker input {
  padding: 0 10px;
  font-size: 14px;
}

.edit-SOW__date-picker button {
  padding: 8px;
}

.edit-SOW__text-field textarea {
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
}

.edit-SOW__select>div, .edit-SOW__select>div>div, .edit-SOW__text-field>div, .edit-SOW__text-field>div>div, .edit-SOW__date-picker>div, .edit-SOW__search-dropdown {
  height: 100%;
}

.edit-SOW__text-field>div {
  padding: 0 0 0 10px;
}

.edit-SOW__text-field>div>div, .edit-SOW__date-picker>div {
  padding: 0 0 0 10px;
  margin: 0;
  overflow: hidden;
}

.edit-SOW__select>div>div {
  display: flex;
  align-items: center;
}

.edit-SOW__text-field>div::after, .edit-SOW__text-field>div::before, .edit-SOW__select::after, .edit-SOW__select::before, .edit-SOW__date-picker>div::before, .edit-SOW__date-picker>div::after {
  content: none !important;
}

.edit-SOW__select>div>div {
  padding: 0 20px;
  font-size: 14px;
}

.edit-SOW__checkbox-container {
  display: flex;
  align-items: center;
}

.edit-SOW__checkbox-container>label {
  cursor: pointer;
}

.margin-left-right-10 {
  margin: 0 10px;
}

.redux-form__SOW--buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 70px;
}

.row__items--align-start {
  align-items: flex-start;
}
