.add-employee-container {
  display: flex;
  flex-direction: column;
}

.add-employee-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.add-employee-title {
  font-weight: bold;
  text-transform: uppercase;
}

.add-employee-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3s;
  height: 35px;
  width: 100px;
}

.add-employee-button:hover {
  border: 1px solid #F26939;
}

.add-employee-button-disabled {
  cursor: default;
  color: #D7D7D7;
}

.add-employee-button-disabled:hover {
  border: 1px solid #D7D7D7;
}

.add-employee-list {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.add-employee-list-line {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D7D7D7;
  z-index: 1;
  transition: 0.3s;
}

.add-employee-list-header {
  position: sticky;
  z-index: 10;
  top: 0;
  background: #ffffff;
  border-top: 1px solid #D7D7D7;
}

.disabled-line {
  background: #d9d9d9 !important;
}

.full-width-height {
  width: 100%;
  height: 100%;
}

.add-employee-list-column {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 250px;
  height: 100%;
  padding: 10px 10px;
}

.employee-col {
  max-width: 100%;
}

.add-employee-shadow-icon {
  padding: 0 10px 0 0;
}

.svg-col {
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  position: relative;
}

.svg-col>i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.active-button:hover path {
  fill: #F26939;
}

.add-employee-list-column-title {
  font-family: Roboto;
  font-style: italic;
  font-size: 13px;
}

.add-employee-list-value {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.add-employee-new-item {
  display: flex;
  background: #F8F8F8;
}

.add-employee-validation-error {
  color: red;
}

.add-employee-form {
  width: 100%;
}

.add-employee-search-dropdown-select>div {
  border: 1px solid hsl(0, 0%, 80%) !important;
  box-shadow: none;
  cursor: pointer;
  width: 100%;
}

.add-employee-input input {
  padding: 0 20px;
}

.add-employee-date-picker input {
  margin: 0 -40px 0 0;
}

.add-employee-input>div::before, .add-employee-input>div::after, .add-employee-select::after {
  content: none !important;
}

.add-employee-input>div {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 4px;
  background: white;
}

.input-error>div {
  border: 1px solid red !important;
}

.add-employee-select>div>div {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
}

.add-employee-select>div>div:focus {
  background: white !important;
}

.add-employee-status {
  padding: 5px 10px;
  border-radius: 5px;
}

.add-employee__menu-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
}

.add-employee__menu-options__option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 35px;
  background: white;
  cursor: pointer;
  transition: 0.3s;
}

.menu-options {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: transparent;
  border-radius: 50%;
  transition: 0.3s;
}

.menu-options:hover, .add-employee__menu-options__option:hover {
  background: #DFDFDF;
}

.add-employee__span--text {
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
}