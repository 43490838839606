.shadow-container-edit-associated-roles {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(34,34,34,0.8);
}
.wrapper-edit-associated-roles {
    position: absolute;
    width: 70%;
    min-width: 700px;
    height: 70vh;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    border: 1px solid #DFDFDF;
    z-index: 50;
    background:#FFFFFF;
}
.edit-associated-roles-cross {
    float: right;
    margin: 30px 30px 0 0;
    cursor: pointer;
}
.cross-associated {
    display: 'flex';
    padding: '0 0 0 5px';
}
.edit-associated-roles-items {
    width: 90%;
    margin: 60px auto;
    position: relative;
    padding-bottom: 20px;
}
.edit-associated-roles-buttons {
    position: absolute;
    width: 100%;
}
.associated-roles-buttons {
    width: 93% !important;
}
.wrapper-edit-associated-roles h1 {
    margin-left: 20px;
    width: 400px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
}
.wrapper-edit-associated-roles p {
    margin-left: 20px;
    width: 800px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
}