.vacation-container {
  display: flex;
  flex-direction: column;
}

.vacation-toolbar {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
  flex-shrink: 0;
  height: 60px;
  padding: 15px 0;
}

.vacation-year-selector {
  height: 35px;
  width: 80px;
}

.vacation-year-selector>div {
  background: #F2F2F2;
  height: 100%;
}

.vacation-year-selector>div::before {
  content: none;
}

.vacation-year-selector>div>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vacation-year-selector>div>div>div {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
}

.vacation-periods {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.period-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
  height: 30px;
  background: #5168BC;
  color: white;
  margin: 10px 35px 10px 0;
  padding: 0 10px;
  border-radius: 5px;
}

.vacation-calendar {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0 0 35px;
}

.month-container {
  display: flex;
  flex-direction: column;
  width: calc(35px * 7);
  height: calc(35px * 8);
  margin: 10px;
}

.month-name {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #d7d7d7;
}

.week-days {
  display: flex;
  width: 100%;
  height: 35px;
  color: #828282;
}

.weeks-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.day-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-shrink: 0;
  width: 35px;
  height: 35px;
}

.day-container span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 15px;
}

.day {
  transition: 0.3s;
  cursor: default;
}

.day:hover {
  color: black;
  background: #E5E5E5;
  border-radius: 50%;
}

.selected-day {
  background: #5168BC;
  color: white;
  border-radius: 0 !important;
}

.weekend {
  color: red !important;
}

.first-selected-day {
  border-radius: 50% 0 0 50% !important;
}

.last-selected-day {
  border-radius: 0 50% 50% 0 !important;
}

.single-selected-day {
  border-radius: 50% !important;
}

.vacations-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  max-width: 450px;
  height: 100%;
  max-height: 300px;
  margin: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 5;
}

.vacations-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px;
  border-bottom: 1px solid #D7D7D7;
}

.vacations-modal__header div {
  cursor: pointer;
}

.vacations-modal__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px 0 20px;
}

.vacations-modal__content>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.vacations-modal__date-picker {
  position: relative;
}

.vacations-modal__date-picker>div {
  padding: 10px;
  width: 170px;
}

.vacations-modal__date-picker>p {
  position: absolute;
  top: 100%;
}

.vacations-modal__date-picker>div>input {
  padding: 0 0 0 10px;
}

.vacations-modal__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #D7D7D7;
}

.vacations-modal__apply {
  background: #F26939;
  color: white;
}

.validation-message {
  margin: 7px 0 0 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #f44336;
  width: 50%;
}

.validation-message--hidden {
  visibility: hidden;
}

.read-only-vacation-tag {
  width: 245px
}

.vacations-modal-body-contetn-center {
  margin-top: 15px;
}

.vacations-tag-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30px;
}

.vacation-modal__checkbox .checkbox__container {
  width: 200px;
}

.vacation-modal__checkbox .checkbox__container .checkbox__title {
  color: rgb(0, 0, 0);
  padding: 0;
  font-size: 14px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
