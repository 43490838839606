.loader-wrapper {
    position: fixed;
    background-color: rgba(53, 52, 52, 0.4);
    width: 100%;
    height: 100%;
    z-index: 100;
}
.preloader {
    margin: 20% auto;
    width: 174px;
    position: relative;
}
.loader-theodolite {
    position: absolute;
    background: #ffffff;
    width: 42px;
    height: 42px;
    margin: 23px 0 0 62px;
}
.loader-circle {
    width: 30px;
    height: 30px;
    margin: 6px auto;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(to right, #F26939 10%, rgba(242, 105, 57, 0) 60%);
    background: -webkit-linear-gradient(to right, #F26939 10%, rgba(242, 105, 57, 0) 60%);
    background: -o-linear-gradient(to right, #F26939 10%, rgba(242, 105, 57, 0) 60%);
    background: -ms-linear-gradient(to right, #F26939 10%, rgba(242, 105, 57, 0) 60%);
    background: linear-gradient(to right, #F26939 10%, rgba(242, 105, 57, 0) 60%);
    position: relative;
    -webkit-animation: load 1.4s infinite linear;
    animation: load 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader-circle:before {
    width: 50%;
    height: 50%;
    background: #F26939;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader-circle:after {
    background: #FFFFFF;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .loader-circle.loader-import {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  .loader-circle.loader-import:after {
		background: #E4E4E4;
  }

  @-webkit-keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }