.page-wrapper {
    width: 100%;
    height: 100%;
    user-select: none;
}
.version-wrapper {
    position: absolute;
    bottom: 10px;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center
}
.version-container {
    width: 60px;
    margin: auto;
}
.light-part {
    color: #FFFFFF;
}
.dark-part {
    margin-left: 10px;
}

.content-wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    float: left;
    justify-content: center;
    align-items: center;
    background: white;
}
.logo-wrapper {
    background: #4B4B4B;
    display: flex;
}
.content-wrapper form {
    width: 70%;
    height: 70%;
    max-width: 400px;
}
.content-wrapper > form > h1 {
    font-family: Roboto;
    font-style: normal;
    line-height: normal;
    font-size: 48px;
    text-transform: uppercase;
    font-weight: 300;
}
.content-wrapper > form > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 14px;
    color: #4B4B4B;
    margin: 0px;
}
.content-wrapper > form > h3 {
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    line-height: 25px;
    font-size: 13px;
    color: #F26939;
}

.sign-error-container {
    font-family: Roboto;
    font-style: italic;
    line-height: normal;
    color: #F26939;
    min-height: 17px;
    margin-bottom: 10px;
}