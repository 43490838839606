.extend-roles-cross {
    float: right;
    margin: 30px 30px 0 0;
    cursor: pointer;
}
.extend-roles-body {
    width: 90%;
    margin: 56px auto;
}
.extend-roles-body h1 {
    width: 200px;
    height: 31px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: normal;
    text-transform: uppercase;
}
.extend-role-select {
    margin: 0 0 40px 0;
    display: inline-flex;
}
.extend-roles-buttons {
    position: relative;
    height: 20px;
}
.extend-buttons {
    right: 90px;
}