.c-modal {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  position: absolute;
  width: 100%;
  max-width: 60%;
  /* max-width: 1042px; */
  height: 100%;
  max-height: 90%;
  /* max-height: 658px; */
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 999;
}

.c-modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.6);
  z-index: 4;
}
