.c-list__item-cont {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  color: #4b4b4b;
  height: 50px;
  transition: 0.3s;
  cursor: pointer;
}

.c-list__item-hidden {
  visibility: hidden;
}

.c-list__item-cont:hover {
  background: #fff3e5 !important;
}

.c-list__item-cont:nth-child(even) {
  background: #f8f8f8;
}

.c-list__item-cont:nth-child(odd) {
  background: white;
}
.item-with-error {
  background: rgba(235, 87, 87, 0.15) !important;
}

.item-overriden {
  background: rgba(255, 231, 56, 0.15) !important;
}

.item-red-bg {
  background: rgba(235, 64, 52, 0.15)  !important;
}

.item-yellow-bg {
  background: rgba(245, 245, 27, 0.15) !important;
}

.c-list__item-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-basis: 150px;
  padding: 0 10px;
  overflow: hidden;
  height: 35px;
  white-space: pre-wrap;
}

.c-list__item-column--datepicker {
  overflow: visible;
}

.c-list__item-column a {
  text-decoration: none;
  color: #4b4b4b;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 14px;
  max-height: 28px;
}

.c-list__item-column a:hover {
  text-decoration: underline;
}

.c-list__item-column svg {
  transition: 0.2s;
}

.c-list__item-column__link {
  text-decoration-line: underline;
  color: #f26939;
}

.c-list-item-datepicker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: .1;
  flex-basis: 150px;
  padding: 0 10px;
  overflow: hidden;
  white-space: pre-wrap;
  height: 35px;
  margin-bottom: 15px;
}

.c-list-item-datepicker--input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.c-list-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  margin: 0 10px 0 0 !important;
  width: 24px;
  height: 24px;
  color: white;
  font-size: 12px;
}

.c-list-logo--withoutTitle {
margin: 0 0 0 10px !important;
}

.c-list__bg-or {
  background: #f2994a;
}

.c-list__bg-bl {
  background: #56ccf2;
}

.c-list-logo--badge {
  font-size: 12px;
  color: #ffffff;
}

.c-list__badges {
  display: flex;
}

.c-list__badges__amount {
  font-size: 13px;
  color: #4b4b4b;
  line-height: 23px;
  margin-left: 4px;
}

.c-list__badges .c-list-logo {
  margin: 0 0 0 -5px;
}

.opened-svg {
  transform: rotate(90deg);
}

.c-list-item__options-column__container {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
}

.c-list-item__options-column__option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 35px;
  background: white;
  position: relative;
}

.c-list-item__options-column__option:hover {
  background: #dfdfdf;
  cursor: pointer;
}

.col-span {
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 0;
}
.col-span-header {
  overflow: visible;
}

.col-span__with-nowrap {
  white-space: nowrap;
}

.delete-button-trashbox {
  width: 60%;
  height: 50%;
}
.checkbox_width {
  max-width: 30px;
}
.check_role {
  padding: 0 !important;
}
.open-role {
  padding-left: 10px;
}
.item-link:hover {
  text-decoration: underline;
}
.item-link-no-hover:hover {
  cursor: pointer;
  text-decoration: none;
}
.list-item-comment {
  margin: -40px 0 0 25px;
  max-width: 180px;
  max-height: 180px;
  min-width: 100px;
  min-height: 30px;
  position: absolute;
  overflow: auto;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.14);
  background: #ffeedf;
  border: 1px solid #a5a5a5;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: normal;
  color: #727272;
  padding: 10px;
  z-index: 10;
}
.sparkline {
  width: 200px;
  font-size: 8px;
}
.barchart_modal {
  position: absolute;
  width: 80%;
  height: 80%;
  min-width: 700px;
  min-height: 400px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 20px 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  font-size: 12px;
}
.barchart_modal > div {
  margin: 20px auto;
}
.barchart_modal-cross {
  float: right;
  margin-top: 10px;
  cursor: pointer;
}

.c-list-item_push-options-up_low {
  top: -30px;
}

.c-list-item_push-options-up_middle {
  top: -100px !important;
}

.c-list-item_push-options-up_high {
  top: -175px !important;
}

.c-list-item_push-options-up_low-middle {
  top: -70px !important;
}

.column_custom-cell {
  display: flex;
  align-items: center;
}

.col-span_custom-cell {
  color: #c00000;
  text-decoration-line: underline;
}
.cell_column-orient {
  box-orient: vertical;
}
.custom-cell_clock {
  padding-left: 5px;
}

.c-skill__item div {
  width: 100%;
}

.skills-level-select__select-indent div div {
  padding-right: 20px;
}

.audit-changes-date {
  display: flex;
  flex-direction: row;
}

.audit-changes-date i {
  margin: 5px 2px 0 2px;
}

.audit-changes-date span {
  max-height: 20px;
  max-width: 200px;
  text-overflow: ellipsis;
}

.audit-changes-date > div {
  display: inline-block;
  padding: 0 5px 0 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
}

.audit-filters__datapicker--end-date {
  margin-left: 20px !important;
}

.field__multi-select-cell {
  width: 350px !important;
}

.field__multi-select-cell
  .field__multi-select-rel-cont
  .field__multi-select-cont
  .multi-select__options-container {
  width: 350px !important;
}
.highorder_component-wrapper {
  border: 1px solid #d7d7d7;
  margin: 10px 20px 10px 40px;
}

.checkbox_list_container {
  display: flex;
  justify-content: flex-start;
  z-index: 1000;
  min-width: 150px;
  height: 35px;
  background: white;
  transition: 0.3s;
  position: relative;
  align-items: center;
}
.checkbox_list_container label {
  margin-left: 5px !important;
}
.checkbox_list_container:hover {
  background: #dfdfdf;
  cursor: pointer;
}
.list-item--actions {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 74px;
  margin-right: 10px;
}
.list-item--actions.c-list__options-column {
  min-width: 74px;
}
.list-item--actions > .list-item--action:last-child {
  margin-right: 0;
}
.list-item--actions .list-item--action {
  margin-right: 10px;
}
.list-item--action {
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  background: none;
  border: none;
  opacity: 0.75;
  cursor: pointer;
}
.list-item--action:hover,
.list-item--action:focus {
  opacity: 1;
}
.list-item--action:active {
  opacity: 0.5;
}
div.big-report-col {
  min-width: 130px !important;
}
div.medium-report-col {
  min-width: 110px !important;
}
div.small-report-col {
  min-width: 90px !important;
}
.page-container_for-accounting .c-list__status,
.page-container_for-accounting .small-col,
div.fixed-medium-report-col {
  width: 110px;
  max-width: initial;
}
.disabled-item {
  opacity: 0.75;
}

.error-value {
  color: red;
  font-weight: bolder;
}
